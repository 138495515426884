//angular-oidc-client authentication
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

export const authClientId = 'angular-client';
export const authIdpAuthorityUrl = 'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration';
//"https://schidentityserverdev.azurewebsites.net";
export const authCookieKey = 'bluePrint_auth_cookie_key';

//msal authentication
export const msalTenantUrl = 'https://login.microsoftonline.com/organizations';
export const previewImageUrl = 'https://schmappstracc.z13.web.core.windows.net/assets/images/Machine%20Library.svg';

export const initialHazards = 'Initial_Hazards';
export const offerDetails = 'offerDetails';
export const roadmapLibrary = 'Roadmap_Library';
export const standardsDocuments = 'Standards_Documents';
export const legislationsDocuments = 'Legislations_Documents';
export const riskAssessmentReport = 'RiskAssessment_Report';
export const quoteTemplateLibrary = 'Quote_TemplateLibrary';
export const machineLibrary = 'Machine_Library';
export const controlMeasures = 'Control_Measures';
export const machineDetailedPageDefaultTab = 'machineInfo';
export const machineStatusList = ['Operational', 'Out of Service', 'Under Maintenance', 'Emergency Shutdown', 'Calibration', 'Testing', 'Idle', 'Standby', 'Faulty', 'Pending Approval', 'Scheduled Shutdown', 'Decommissioned', 'Unknown'];
export const machineModeList = [{ machineMode: 'Normal' }, { machineMode: 'Setup' }, { machineMode: 'Maintenance' }, { machineMode: 'Other' }];
export const lifeCyclePhaseList = [{ lifeCyclePhase: 'Others' }, { lifeCyclePhase: 'Take out of Service' }, { lifeCyclePhase: 'Disassembly' }, { lifeCyclePhase: 'Transport' }, { lifeCyclePhase: 'Assembly' }, { lifeCyclePhase: 'Put into Service' }, { lifeCyclePhase: 'Waste Disposal' }, { lifeCyclePhase: 'Installation' }, { lifeCyclePhase: 'Use' }];
export const availableLngs = [{ title: 'English', val: 'Eng' }, { title: 'Portuguese', val: 'Por' }, { title: 'German', val: 'Ger' }, { title: 'Spanish', val: 'Spa' }];
export const availableTemplates = [
  { title: 'Standard US Template', val: 'us' },
  { title: 'Standard German Template', val: 'gm' }
];
export const USA_ADDRESS = {
  date: moment().format('DD-MM-YYYY'),
  line1: 'Schmersal USA',
  line2: '15 Skyline Drive',
  line3: 'Hawthorne, NY 10532',
  line4: 'P: 914-347-4775',
  line5: 'F: 914-347-1567',
  line6: 'https://www.schmersalusa.com'
};

export const Germany_ADDRESS = {
  date: moment().format('DD-MM-YYYY'),
  line1: 'Schmersal Germany',
  line2: '15 Skyline Drive',
  line3: 'Hawthorne, NY 10532',
  line4: 'P: 914-347-4775',
  line5: 'F: 914-347-1567',
  line6: 'https://www.schmersalusa.com'
};

export class AppConstants {
  public static USER = 'user';
  public static ROLE = 'role';
  public static GROUP = 'group';
  public static RBAC = 'rbac';
  public static DELETE = 'Delete';
  public static SUCCESS_SNACKBAR = 'success';
  public static WARNING_SNACKBAR = 'warning';
  public static ERROR_SNACKBAR = 'danger';
  public static ARE_YOU_SURE_TO_DELETE = 'Are you sure you want to delete ?';
  public static ARE_YOU_SURE_TO_CANCEL = 'Are you sure you want to cancel?';
  public static ARE_YOU_SURE_TO_EDIT_PROJECT = 'Are you sure you want to edit the project ?';
  public static ARE_YOU_SURE_TO_DELETE_PROJECT = 'Are you sure you want to delete the Project ?';
  public static ARE_YOU_SURE_TO_BLOCK_PROJECT = 'Are you sure you want to block this project ?';
  public static ARE_YOU_SURE_TO_RELEASE_PROJECT = 'Are you sure you want to release this project ?';
  public static ARE_YOU_SURE_TO_CLOSE_PROJECT = 'Are you sure you want to close this project ?';
  public static ARE_YOU_SURE_TO_DELETE_OFFER = 'Are you sure you want to delete this offer ?';
  public static ARE_YOU_SURE_TO_EDIT_OFFER = 'Are You Sure You want to edit this offer ?';
  public static ARE_YOU_SURE_TO_VIEW_OFFER = 'Are you sure you want to view this offer ?';
  public static ARE_YOU_SURE_TO_ASSIGN_OFFER = 'Are you sure you want to assign this offer ?';
  public static ARE_YOU_SURE_TO_DELETE_SERVICE = 'Are you sure you want to delete this service';
  public static ARE_YOU_SURE_TO_LOGOUT = 'Are you sure you want to logout ?';
}

export const CharDateRanges = [
  { title: 'Day', id: 1 },
  { title: 'Last Week', id: 2 },
  { title: 'Month', id: 3 },
];
export const offerLegends = ['product', 'Total Offers', 'Offers Approved', 'Offer Pending Assignment', 'Offer Converted Order', 'Offer Pending Approval'];

export const projectLegends = ['product', 'Total Projects', 'Services In Progress', 'Services Completed', 'Total Services'];

export const orderLegends = ['product', 'Total Orders', 'Orders In Progress', 'Order Completed'];


export const barSeries = [{
  type: 'bar',
  barGap: 1,
  color: '#4472c4',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 1,
  color: '#a5a5a5',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  color: '#5b9bd5',
  barGap: 1,
  emphasis: {
    focus: 'series'
  }
}, {
  type: 'bar',
  barGap: 1,
  color: '#1e4b8d',
  emphasis: {
    focus: 'series'
  },
},
];

export const stackedBarSeries = [{
  type: 'bar',
  barGap: 0,
  stack: 'total',
  barWidth: '50%',
  color: '#4472c4',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#a5a5a5',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#5b9bd5',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#1e4b8d',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}
];


export const offerBarSeries = [{
  type: 'bar',
  barGap: 1,
  color: '#4472c4',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 1,
  color: '#a5a5a5',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  color: '#5b9bd5',
  barGap: 1,
  emphasis: {
    focus: 'series'
  }
}, {
  type: 'bar',
  barGap: 1,
  color: '#1e4b8d',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 1,
  color: '#1a46d0',
  emphasis: {
    focus: 'series'
  },
},
];

export const offerStackedBarSeries = [{
  type: 'bar',
  barGap: 0,
  stack: 'total',
  barWidth: '50%',
  color: '#4472c4',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#a5a5a5',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#5b9bd5',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#1e4b8d',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  stack: 'total',
  barWidth: '50%',
  color: '#1a46d0',
  emphasis: {
    focus: 'series'
  },
},
];

export const orderBarSeries = [{
  type: 'bar',
  barGap: 1,
  color: '#4472c4',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 1,
  color: '#a5a5a5',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  color: '#5b9bd5',
  barGap: 1,
  emphasis: {
    focus: 'series'
  }
}
];

export const orderStackedBarSeries = [{
  type: 'bar',
  barGap: 0,
  stack: 'total',
  barWidth: '50%',
  color: '#4472c4',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#a5a5a5',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}, {
  type: 'bar',
  barGap: 0,
  color: '#5b9bd5',
  stack: 'total',
  barWidth: '50%',
  emphasis: {
    focus: 'series'
  },
}
];
export class ObservationEvents {
  public static OFFER_DETAIL_FORM_VALIDITY = 'OfferDetailFormValidity';
  public static STEPPER_TYPE = 'steperType';
  public static MACHINE_FILTER = 'machineFilter';
  public static CLOSE_FILTER_POPUP = 'closeFilterPopup';
  public static APPLIED_FILTER = 'appliedFilter';
  public static IS_OPENED_BY_BTN = 'isOpendByFilterBtn';
  public static EDIT_FILTER = 'editFilter';
  public static CLOSE_RBAC = 'closeRbac';
  public static GATEWAY_TIMEOUT = 'gatewayTimeout';
  public static FILE_UPLOAD = 'fileUpload';
  public static ERROR_OCCURRED = 'errorOccurred';
  public static FILE_UPLOAD_SUCCESS = 'fileUploadSuccess';
  public static DOWNLOAD_FILE = 'downloadFile';
}

export class libraryConstants {
  public static MACHINE_LIBRARY = 'Machine Library';
  public static STANDARD_DOCUMENTS = 'Standard Documents';
  public static LEGISLATION_DOCUMENTS = 'Legislation Documents';
  public static ROADMAPS = 'Roadmaps';
  public static USER_ROADMAPS = 'User Roadmaps';
  public static RISK_ASSESSMENT_REPORT = 'Risk Assessment Static Report Templates';
  public static QUOTE_TEMPLATES = 'Quote Templates';
  public static CONTROL_MEASURES = 'Control Measures';
  public static INITIAL_HAZARDS = 'Initial Hazards';
}

export class urlPathConstants {
  public static defaultMachineUrl = 'assets/images/Machine Library.svg';
  public static viewProjectPath = 'project-management/project-detail/';
  public static viewOfferPath = 'offer-management/view-offer/';
  public static viewOrderPath = 'order-management/view-order/';
  public static viewServicePath = 'order-management/project-detail/';
  public static imageUploadUrl = environment.riskassessmentBaseUrl + 'upload_image';
  public static userVideoGuide = 'https://dnaofsafetydev.sharepoint.com/:u:/r/sites/BluePrint/SitePages/Blue.print-Video-Guide.aspx?csf=1&web=1&share=EXCzPFngqPZDqBC_Sl1uH3wBLdDuggy_hK_Tsx7t8PKttw&e=otcG9i';
}

export class StorageKeys {
  public static NEW_HAZARD_DETAILS = 'newhazardDetails';
  public static ASSIGNED_STAFF = 'assignedStaff';
}

export class CustomerManagementConstants {
  public static SALES_ORGNIZATION = 'Sales Organization';
  public static CONTACTS = 'Contacts';
  public static CUSTOMER = 'Customer';
  public static SERVICE_MATERIAL = 'Service Material';
}

export class ServiceManagementConstants {
  public static SERVICE_DETAILS = 'Service Details';
  public static MACHINE_DETAILS = 'Machine Details';
}

export class MachineManagementConstants
{
  public static ALL_MACHINES = 'All Machines';
  public static ATTRIBUTES_MANAGEMENT = 'Attributes Management';
}

export class ProjectDetailsConstants
{
  public static PROJECT_OVERVIEW = 'Project Overview';
  public static PROJECT_SERVICE_DETAILS = 'Service Details';
  public static SERVICE_ROADMAP_DETAILS = 'Service Roadmap Details';
  public static MACHINE_DETAILS = 'Machine Details';
  public static LOG_ACTUAL_HOURS = 'Log Actual Hours';
  public static PROJECT_ITEMS_VIEW = 'Project Items View';
}

export class UserManagementConstants
{
  public static USER_MANAGEMENT = 'All Machines';
  public static ROLE_MANAGEMENT = 'Role Management';
  public static GROUP_MANAGEMENT = 'Group Management';
  public static RBAC_PERMISSIONS = 'RBAC Permissions';
}

export class MachineInfoConstants
{
  public static MACHINE_DETAILS = 'Machine Details';
  public static ATTRIBUTES_DETAILS = 'Attributes Details';
  public static MACHINE_LIMITS = 'Machine Limits';
  public static MACHINE_REFERENCES = 'Machine References';
  public static MACHINE_IMAGES = 'Machine Images';
}

export class MachineDetailConstants {
  public static MACHINE_DETAILS = 'Machine Details';
  public static ATTRIBUTES_DETAILS = 'Attributes Details';
  public static MACHINE_LIMITS = 'Machine Limits';
  public static ROADMAPS = 'Roadmaps';
  public static SISTEMA = 'Sistema';
}

export class PathNames {
  public static SALES_ORGNIZATION = 'sales-organization';
  public static CUSTOMER = 'customer';
  public static CONTACTS = 'contacts';
}

export function getApiCallDescription(apiCallType: string, title: string): string {
  switch (apiCallType) {
    case 'edit':
      return title + ' updated successfully';
    case 'delete':
      return title + ' deleted successfully';
    default:
      return title + ' created successfully';
  }
}

export const Language = {
  Eng: 'English',
  Por: 'Portuguese',
  Spa: 'Spanish',
  Ger: 'German'
};
export const selectRoadmapFilter = [
  { label: 'Yes, Compliant', key: 'is_compliant', value: true },
  { label: 'Not Compliant', key: 'is_not_compliant', value: true },
  { label: 'Not Applicable', key: 'is_not_applicable', value: true },
  { label: 'Report Hazard', key: 'is_hazard', value: true },
];

export class IconUrls {
  public static SUCCESS_ICON = '../../assets/icon/success_icon.svg';
  public static SUCCESS_CLOSE = '../../assets/icon/success_close.svg';

  public static WARNING_ICON = '../../assets/icon/warning_icon.svg';
  public static WARNING_CLOSE = '../../assets/icon/warning_close.svg';

  public static ERROR_ICON = '../../assets/icon/error_icon.svg';
  public static ERROR_CLOSE = '../../assets/icon/error_close.svg';
}

export class Froala_Constants {
  public static LICENSE_KEY = 'uXD2lA6F6F5E3A3A3A11dNSWXf1h1MDb1CF1PLPFf1C1EESFKVlA3C11A8D7D2B4G3G2F3A2==';
}