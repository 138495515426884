import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AuthInterceptor } from './services/http-interceptor';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BackendCallService } from './services/backend-call/backend-call.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import {
  MsalModule,
  MsalRedirectComponent,
  MsalGuard,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalBroadcastService,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { SortingPipe } from './pipes/sorting.pipe';
import { componentModule } from './components/components.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageSupportService } from './services/language-support.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthConfigModule } from './services/user-auth/auth-config.module';
import { msalTenantUrl } from './utilities/constant';
import { loginRequest, msalConfig, protectedResources } from './services/user-auth/auth-config';
import { NgxPrintModule } from 'ngx-print';
import { EventProvider } from './services/event';
import { EllipsisPipe } from './pipes/EllipsisPipe';
import { TitleCasePipe } from './pipes/titlecase';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap
    .set(protectedResources.graphApi.endpoint, protectedResources.graphApi.scopes)
    .set(protectedResources.tokenService.endpoint, protectedResources.tokenService.scopes)
    .set(protectedResources.graphAPIUserInfo.endpoint, protectedResources.graphAPIUserInfo.scopes)
    .set(protectedResources.getListItems.endpoint, protectedResources.getListItems.scopes)
    .set(protectedResources.createTeam.endpoint, protectedResources.createTeam.scopes)
    .set(protectedResources.graphUploadFile.endpoint, protectedResources.graphUploadFile.scopes)
    .set(protectedResources.getGroups.endpoint, protectedResources.getGroups.scopes)
    .set(protectedResources.createFolder.endpoint, protectedResources.createFolder.scopes)
    .set(protectedResources.getSiteId.endpoint, protectedResources.getSiteId.scopes)
    .set(protectedResources.getFolderDetails.endpoint, protectedResources.getFolderDetails.scopes)
    .set(protectedResources.getOfferQuotTemplates.endpoint, protectedResources.getOfferQuotTemplates.scopes)
    .set(protectedResources.getOfferDrives.endpoint, protectedResources.getFolderDetails.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {

  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
    declarations: [AppComponent, SortingPipe],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        FormlyBootstrapModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        NgxDocViewerModule,
        PdfViewerModule,
        NgxPrintModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        componentModule,
        AuthConfigModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        BackendCallService,
        SortingPipe,
        LanguageSupportService,
        EventProvider,
        EllipsisPipe,
        TitleCasePipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
