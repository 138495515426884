import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { Location } from '@angular/common';
import { AlertModalPage } from 'src/app/components/alert-modal/alert-modal.page';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { addUniqueEntry, isNotBlank, isNotEmptyArray, isNotNullAndUndefined, isObjectEmpty } from 'src/app/utilities/utils';
import { AddMachineToBucketComponent } from 'src/app/components/add-machine-to-bucket/add-machine-to-bucket.component';
import { StatusPopoverComponent } from 'src/app/components/status-popover/status-popover.component';
import { FilterMoodalComponent } from 'src/app/components/filter-moodal/filter-moodal.component';
import { SearchModalComponent } from 'src/app/components/search-modal/search-modal.component';
import * as moment from 'moment';
import { ProjectManagementLayoutPage } from '../project-management-layout/project-management-layout.page';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import { GraphService } from 'src/app/services/graph/graph.service';
import { LocalSettingsService } from 'src/app/services/local-settings/local-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { AddCustomRoadmapsComponent } from 'src/app/components/add-custom-roadmaps/add-custom-roadmaps.component';
import { AddPredefinedRoadmapsComponent } from 'src/app/components/add-predefined-roadmaps/add-predefined-roadmaps.component';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { RoadMapSection, RoadMapSubSection, StepMaster } from '../machine-details/machine-details-interface';
import { ActualHours, ActualHoursList, AssignedStaffList, BucketData, BucketDetails, BucketMachines, BudgetAndCostData, MachineDetailsList, ProjectDetails, ProjectsOverviewList, SelectedOrderServiceTypeID, ServiceDetailsList, StaffData } from './project-interface';
import { AppConstants, ServiceManagementConstants,ProjectDetailsConstants, StorageKeys } from 'src/app/utilities/constant';
import { SearchWithMultiSelectCheckboxComponent } from 'src/app/components/search-with-multi-select-checkbox/search-with-multi-select-checkbox.component';
import { ToastService } from 'src/app/services/toast.service';
import { GridConfig } from '../../../shared/grid-config';
import { AppGridComponent } from '../../../shared/grid/grid-component/grid-component';
import { ColDef } from 'ag-grid-community';
import { ConfigureRoadmapComponent } from '../../project-management/machine-details/configure-roadmap/configure-roadmap.component';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.page.html',
  styleUrls: ['./project-detail.page.scss'],
})
export class ProjectDetailPage implements OnInit, OnDestroy {
  @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
  roadmapGridColumnDefs: ColDef[];
  roadmapGridConfigItems: GridConfig; 
  projectList: ProjectDetails;
  public tabs = ProjectDetailsConstants;
  public activeTab = ProjectDetailsConstants.PROJECT_OVERVIEW;
  public prjDetailSelectedSegment = 'projectInformation_segment';
  selectServiceOrderOptions = [];
  serviceTypesList: ServiceDetailsList = [];
  prodetailsData: SelectedOrderServiceTypeID = {
    selectOrder: null,
    serviceType: null,
    serviceId: null,
  };
  public activeTab1 = ServiceManagementConstants.SERVICE_DETAILS;
  public tabs1 = ServiceManagementConstants;
  // For now set to true, to check the subsegment on service details
  IsEmptyCard = true;
  public isFromSAP = false;
  serviceTabData: any = {};
  machineListInfo: MachineDetailsList = [];
  UserIdAssign: string;
  user_Role: string;
  projectId: any;
  isAllMachineSelected = false;
  isAnyMachineListSelected = false;
  isviewFromSearch = false;
  machineListViewType = 'grid-view';
  machineSegmentType = 'all-machine';
  myMachineLists: any = [];
  allMachineListsInfo = [];
  serviceRoadmaps = [];
  assignedStaffList: AssignedStaffList = [];
  private project_id: string;
  private orderId: string;
  enddatelocal: any;
  private encrypted_project_id: any;
  isAllMachineCheckboxSelected = false;
  allBucketLists: BucketData[] = [];
  logActualHoursInfo = [];
  islogActualHoursEditing = false;
  logActualHoursListsToEdit = [];
  statusStepperData: any[];
  getProjectServiceTypeLoading: boolean;
  selected_service_Id: any;
  isConflictInAnyMachine: any;
  isBucketListPermission: boolean;
  isBucketDeletePermission: boolean;
  isMachineListPermission: boolean;
  isMachineReadPermission: boolean;
  isBucketWritePermission: boolean;
  isOfferWritePermission: boolean;
  projectOverViewLists: ProjectsOverviewList = [];
  isConflictResolutionPermission: boolean;
  budgetInfo: BudgetAndCostData;
  changeServiceTypeToDropDown = false;
  pageFrom: any;
  public actualHoursList: ActualHoursList = [];
  serviceDetails: any;
  serviceIds: any = [];
  serviceDetailsOfNonSapProject: any;
  selectedServiceIdNonSAP: any = '';
  viewServiceDetails: any = [];
  existingMachineIds: any = [];
  public isMachineAvailable = false;
  public isRoadmapGridDisplayed = true;
  public roadmapName = '';
  public isCustom: any;
  public roadmapData: any;
  selectedSectionView: any;
  public roadmapId='';
  public roadmapStatus='';
  private project_Id: any;
  private service_Id: any;
  constructor(
    private route: ActivatedRoute,
    public toastController: ToastController,
    public navCtrl: NavController,
    public modalController: ModalController,
    public backendService: BackendCallService,
    public popController: PopoverController,
    public sharedService: ShareValuesService,
    private router: Router,
    public popoverController: PopoverController,
    public userAuth: UserAuthService,
    private graphService: GraphService,
    public localSettingsSrv: LocalSettingsService,
    public loading: LoadingController,
    public translate: TranslateService,
    public alertController: AlertController,
    public _location: Location,
    private toastService: ToastService
  ) {
    this.fnInitialiseRoadmapGrid();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('prjDetailSegment');
    sessionStorage.removeItem('selectedServiceIdNonSAP');
  }

 /**
  * Cleans up segment
  */
 public cleanUpSegment() {
    sessionStorage.removeItem('prjDetailSegment');
    sessionStorage.removeItem('selectedServiceIdNonSAP');
  }
  async ngOnInit() {
    if (sessionStorage.getItem('prjDetailSegment')) {
      this.prjDetailSelectedSegment = sessionStorage.getItem('prjDetailSegment');
    } else {
      this.prjDetailSelectedSegment = 'projectInformation_segment';
    }
    switch (this.prjDetailSelectedSegment) {
      case 'projectInformation_segment':
      case 'service_details_segment':
      case 'service_roadmap_details_segment':
        
      case 'machine_details_segment':
      case 'log_actual_hours_segment':
      case 'overview_segment':
        this.getTabDetails(this.prjDetailSelectedSegment);
        break;
      default:
        break;
    }
     
    // EMPTY SELECTED SERVICE ROADMAPS LIST
    this.sharedService.selectedServiceRoadmapsList = [];
    localStorage.removeItem(('machineModeList'));
    this.encrypted_project_id = this.route.snapshot.params.id;
    const queryParams = { id: '', pageFrom: '', serviceType: '', orderId: '', serviceId: '', selectedSegment: '', type: '', globalSearchInput: '', erpOrderId: '', is_from_sap : true }; //this.route.snapshot.queryParams;
    // While opening this page from job order mgmt module via open link in new tab, need to fetch params using queryString
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.route.queryParams.subscribe((params: Params) => {
      this.pageFrom = params?.pageFrom;
      // this.getServiceIdWithDetails();
      if(params.pageFrom == 'orderMang')
        {this.isFromSAP = JSON.parse(params.is_from_sap);}
      else
        {this.isFromSAP = JSON.parse(params.is_sap);}
      queryParams.id = params.id; // Assuming the parameter is named 'id'
      queryParams.pageFrom = params.pageFrom;
      queryParams.serviceType = params.serviceType;
      queryParams.orderId = params.orderId;
      queryParams.erpOrderId = params.erpOrderId;
      queryParams.serviceId = params.serviceId;
      queryParams.selectedSegment = params.selectedSegment;
      queryParams.type = params.type;
      queryParams.globalSearchInput = params.globalSearchInput;
      if(queryParams.erpOrderId) {this.orderId = queryParams.erpOrderId;}
      if(queryString.length > 20) {
        queryParams.serviceType = urlParams.get('serviceType');
        queryParams.orderId = urlParams.get('orderId');
        queryParams.serviceId = urlParams.get('serviceId');
      }
      if(queryParams.orderId) {this.orderId = queryParams.orderId;}
      // Fetch data or update component based on this.projectId
    });
    //WHEN PAGE NAVIGATION FROM SERVICE ID
    if (queryParams.pageFrom == 'orderMang') {
      this.prjDetailSelectedSegment = 'service_details_segment';
      this.pageFrom = queryParams.pageFrom;
      const order = sessionStorage.getItem('orderData');
      const orderData = JSON.parse(order);
      let orderInfo: any = {};
      if(orderData?.orderDetails) {orderInfo = orderData?.orderDetails;}
      if (orderData == null) {
        orderInfo.order_Id = queryParams.orderId;
        orderInfo.erp_Order_Id = queryParams.orderId;
        orderInfo.service_Id = queryParams.serviceId;
        orderInfo.service_Type = queryParams.serviceType;
        orderInfo.project_Id = urlParams.get('project_Id');
      }
      orderInfo.erp_Order_Id = queryParams.erpOrderId;
      this.prodetailsData.serviceId = orderInfo.service_Id;
      this.orderId = orderInfo.erp_Order_Id;
      this.prodetailsData.serviceType = orderInfo.service_Type;
      this.prodetailsData.selectOrder = orderInfo.order_Id;
      await this.getSearchProDetails(orderInfo.project_Id);
      /** Called this fnc to autopopulating the Job Order Id */
      this.selectJobOrderId(orderInfo);
      /** Called this fnc to autopopulating the ServiceId */
      this.getServiceTypeByOrderId(orderInfo.order_Id);
      this.selected_service_Id = orderInfo.service_Id;
      /**Checking for RBAC permissions */
      this.checkPermissions();
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        queryParams: { serviceType: orderInfo.service_Type, orderId: orderInfo.erp_Order_Id, serviceId: orderInfo.service_Id }
      };
      this.router.navigate(window.location.pathname.split('/'), navigationExtras);
      /**Checking Teamsite exist or not*/
      await this.sharedService.checkTeamsSiteExistOrNot(this.projectList.erp_Project_Id, this.projectList.company_Code);
      if (this.prodetailsData.selectOrder && this.prodetailsData.serviceId) {
        /** Fnc to get Service and Machine List Information */
        this.getServiceAndMachineListInfo(
          this.prodetailsData.selectOrder,
          this.prodetailsData.serviceId,
          orderInfo.project_Id
        );
        /**Fnc to get service level roadmaps when */
        this.project_Id = this.route.snapshot.params.id;
        if (this.prodetailsData.serviceId) {
          this.service_Id = this.prodetailsData.serviceId
        }
        this.getServiceRoadmapsList(this.project_Id, this.service_Id);
      }
    } else if (queryParams.pageFrom == 'machineInfo' || undefined) {
      if (queryParams.type == 'projectId') {
        this.prjDetailSelectedSegment = 'projectInformation_segment';
      } else if (queryParams.type == 'serviceId') {
        this.prjDetailSelectedSegment = 'service_details_segment';
      }
      this.pageFrom = queryParams.pageFrom;
      const machineInfo = sessionStorage.getItem('machineInfo');
      const machineInfoData = JSON.parse(machineInfo).orderDetails;
      this.prodetailsData.serviceId = machineInfoData.serviceId;
      this.prodetailsData.serviceType = machineInfoData.service_Type;
      await this.getSearchProDetails(machineInfoData.projectId);
      /** Called this fnc to autopopulating the Job Order Id */
      this.selectJobOrderId(machineInfoData);
      /** Called this fnc to autopopulating the ServiceId */
      this.getServiceTypeByOrderId(machineInfoData.erp_Order_Id);
      this.selected_service_Id = machineInfoData.serviceId;
      /**Checking for RBAC permissions */
      this.checkPermissions();
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        // queryParams: { serviceType: orderInfo.service_Type, orderId: orderInfo.erp_Order_Id, serviceId: orderInfo.service_Id }
      };
      this.router.navigate(window.location.pathname.split('/'), navigationExtras);
      /**Checking Teamsite exist or not*/
      await this.sharedService.checkTeamsSiteExistOrNot(this.projectList.erp_Project_Id, this.projectList.company_Code);
      if (machineInfoData.orderId && machineInfoData.serviceId) {
        /** Fnc to get Service and Machine List Information */
        this.getServiceAndMachineListInfo(
          machineInfoData.erp_Order_Id,
          machineInfoData.serviceId,
          machineInfoData.projectId
        );
        /**Fnc to get service level roadmaps when */
        this.project_Id = machineInfoData.projectId;
        if (machineInfoData.serviceId) {
          this.service_Id = machineInfoData.serviceId
        }
        this.getServiceRoadmapsList(this.project_Id, this.service_Id);
      }
    } else {
      this.encrypted_project_id && this.initializeProjectDetailScreen();
    }

    if (queryParams.serviceType) {
      this.prodetailsData.serviceId = queryParams.serviceType;
    }
    if (queryParams.orderId) {
      this.prodetailsData.selectOrder = queryParams.orderId;
    }
    if (queryParams.serviceType && queryParams.serviceId && queryParams.pageFrom !== 'orderMang') {
      this.selected_service_Id = queryParams.serviceId;
      this.prodetailsData.serviceType = queryParams.serviceType;
      this.prodetailsData.serviceId = queryParams.serviceId;
      /**Checking for RBAC permissions */
      this.checkPermissions();
      /** Fnc to get Service and Machine List Information */
      this.getServiceAndMachineListInfo(
        this.prodetailsData.selectOrder,
        this.prodetailsData.serviceId,
        this.route.snapshot.params.id);
      /**Fnc to get service level roadmaps*/
      this.project_Id = this.route.snapshot.params.id;
      if (this.prodetailsData.serviceId) {
        this.service_Id = this.prodetailsData.serviceId;
      }
      this.getServiceRoadmapsList(this.project_Id, this.service_Id);

    }
    if (queryParams.globalSearchInput) {
      this.projectId = queryParams.globalSearchInput;
      /**Fnc to get Searched Project Details */
      this.getSearchProDetails(this.projectId);
      /**Fnc to get Project service Order Details */
      this.getProServiceOrder(this.projectId);
    }
    /**Subcribing Observable */
    ProjectManagementLayoutPage.searchServiceData.subscribe((res) => {
      this.getServiceListInfo(res.projectListInfo);
      this.getMachineListsInfo(res.machineListInfo);
      this.getBucketListInfo(res.bucketListInfo);
      this.getServiceRoadmapListInfo(res.serviceRoadmaps);
      this.project_Id = this.route.snapshot.params.id;
      if (queryParams.serviceType) {
        this.service_Id =  queryParams.serviceType;
      }
      this.service_Id = sessionStorage.getItem('selectedServiceIdNonSAP');
      this.getServiceRoadmapsList(this.project_Id, this.service_Id);

    });
    /**Fnc to get user information */
    this.getUserList();
  }

  /**
   * To initialize service-roadmap grid
   */
  private fnInitialiseRoadmapGrid() {
    this.fnCreateRoadmapColumnDefs();
    this.fnGetRoadmapGridConfigItems();
    setTimeout(() => {
      this.gridComponent?.initializeGrid(false);
    }, 100);
  }

  /**
   * To create column for service-roadmap grid
   */
  private fnCreateRoadmapColumnDefs() {
    this.roadmapGridColumnDefs = [
      {
        headerName: this.translate.instant('Roadmap'),
        field: 'roadmapName',
        minWidth: 150,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value grid-text-color'
      },
      {
        headerName : this.translate.instant('Updated By'),
        field: 'updatedBy',
        minWidth : 150,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      },
      {
        headerName : this.translate.instant('Updated Date'),
        field: 'updatedAt',
        minWidth: 250,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      },
      {
        headerName : this.translate.instant('Roadmap Status'),
        field: 'roadmapStatus',
        minWidth: 150,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value',
        cellRenderer : 'buttonRenderer'
      },
      {
        headerName: this.translate.instant('History'),
        cellRenderer: 'actionRenderer',
        field: 'userActionField',
        suppressMovable: true,
        suppressMenu: true,
        sortable: false,
        minWidth: 120,
        maxWidth: 250,
        cellClass: 'float-right',
        headerComponentParams:
          [
            {
              buttonClass: 'more-icon',
              buttonTooltip: this.translate.instant('History'),
              userAction: this.translate.instant('History'),
              url: 'assets/icon/History_icon.svg'
            }
          ]
      },
      {
        headerName: this.translate.instant('Actions'),
        cellRenderer: 'actionRenderer',
        field: 'userActionField',
        suppressMovable: true,
        suppressMenu: true,
        sortable: false,
        minWidth: 120,
        maxWidth: 250,
        cellClass: 'float-right',
        headerComponentParams:
          [
            {
              buttonClass: 'more-icon',
              buttonTooltip: this.translate.instant('Edit'),
              userAction: this.translate.instant('Edit'),
              url: 'assets/images/edit.svg'
            },
            {
              buttonClass: 'more-icon',
              buttonTooltip: this.translate.instant('Delete'),
              userAction: this.translate.instant('Delete'),
              url: 'assets/images/Delete.svg'
            }
          ]
      }
    ];
  }

  /**
   * To get the grid config items for service-roadmap grid
   */
  private fnGetRoadmapGridConfigItems() {
    this.roadmapGridConfigItems = new GridConfig(
      'id',
      'id',
      false,
      true,
      true,
      false,
      'desc',
      40,
      'no_data_available',
      this.translate.instant('Roadmaps'),
      '',
      this.translate.instant('Configure Roadmaps'),
      true,
      1,
      'grid-wrapper-height-40',
      '',
      '',
      '',
      ''
    );
  }

  /**
   * To invoke operation on cell click
   *
   * @param params
   */
  public fnOnGridCellClick(params) {
    console.log(params);
    if(params.data.userAction == this.translate.instant('Edit'))
      {this.fnViewRoadmap(params.data);}
    else if(params.data.userAction == this.translate.instant('Delete'))
      { this.deleteRoadmapConfirmation(params.data);}
    else if(params.data.userAction == this.translate.instant('History'))
      { this.fnViewHistory(params.data); }
  }

  isRoadmapHistoryDisplayed : boolean = false;
  historyData : any;
  
  private fnViewHistory(roadmap) {
    const roadmapId = roadmap.id;
    
    this.backendService.getRoadmapHistory(roadmapId, this.service_Id).subscribe((data: any) => {
      
      if(data.statusCode == 200)
      {
        if (data && data?.response) {
          this.isRoadmapHistoryDisplayed = true;
          this.isRoadmapGridDisplayed = false;
          this.historyData = data.response;
        }
      }
      else if(data.statusCode == 404)
      {
        this.isRoadmapHistoryDisplayed = false;
        this.isRoadmapGridDisplayed = true;
        if (data.message == "Data not found") {
          this.toastService.presentToast('No roadmap history found for the roamap.', 3000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      }
    }, err => {
      this.toastService.presentToast('No roadmap history found for the roadmap.', 3000, AppConstants.ERROR_SNACKBAR, 'top');
    })
  }

  /**
   * To configure new roadmap
   */
  public async configureRoadmaps() {
    const modal = await this.modalController.create({
      component: ConfigureRoadmapComponent,
      cssClass: 'medium-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
    });
    await modal.present();
    const { data: { isConfigureRoadmap, isNewRoadmap, selectedPredefinedRoadmapLists } } = await modal.onWillDismiss();
    if (isConfigureRoadmap) {
      if (isNotEmptyArray(selectedPredefinedRoadmapLists)) {this.storeSelectedPredefinedRoadmap(selectedPredefinedRoadmapLists);}
    }
    if (isNewRoadmap) {
      this.isRoadmapGridDisplayed = false;
      this.roadmapName = '';
      this.isCustom = true;
      this.project_Id = this.project_Id;
      this.roadmapData = [];
    }
  }

  /**
   * To invoke exit for displaying service-roadmap grid
   * @param $event 
   */
  fnExitEvent($event)
  {
    this.isRoadmapGridDisplayed = true;
    this.isRoadmapHistoryDisplayed = false;
    this.getServiceRoadmapsList(this.project_Id, this.service_Id);
  }

  /**
   * To view the roadmap details
   * @param roadmap 
   */
  private fnViewRoadmap(roadmap) {
    this.roadmapName = roadmap.roadmapName;
    this.roadmapId = roadmap.id;
    this.roadmapStatus = roadmap.roadmapStatus;
    this.isCustom = roadmap?.isCustom;
    this.backendService.getServiceRoadmapSteps(roadmap.id, this.userAuth.getUserList().userId, this.service_Id, this.project_Id).subscribe((data: any) => {
      if(data) {
        this.roadmapData = data;
        this.selectedSectionView = this.roadmapData?.section[0];
      }
    }, err => {
      this.toastService.presentToast(this.translate.instant('no_results_found'), 4000, AppConstants.ERROR_SNACKBAR, 'top');
    });
    this.isRoadmapGridDisplayed = false;
  }

  changesToDropDown() {
    this.changeServiceTypeToDropDown = true;
  }

  segmentChanged(ev) {
    this.prjDetailSelectedSegment = ev;
    sessionStorage.setItem('prjDetailSegment', this.prjDetailSelectedSegment);
    this.getTabDetails(ev);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        selectedSegment: ev
      },
      queryParamsHandling: 'merge'
    };
    this.router.navigate(window.location.pathname.split('/'), navigationExtras);
  }

  getTabDetails(ev)
  {
    switch(ev)
    {
       case 'projectInformation_segment' : this.activeTab = ProjectDetailsConstants.PROJECT_OVERVIEW;
                                           break;
       case 'service_details_segment': this.activeTab = ProjectDetailsConstants.PROJECT_SERVICE_DETAILS;
                                       break;
       case 'service_roadmap_details_segment': this.activeTab = ProjectDetailsConstants.SERVICE_ROADMAP_DETAILS;
       this.isRoadmapGridDisplayed = true;
       this.service_Id = sessionStorage.getItem('selectedServiceIdNonSAP');
       this.getServiceRoadmapsList(this.project_Id, this.service_Id);
                                               break;
       case 'machine_details_segment': this.activeTab = ProjectDetailsConstants.MACHINE_DETAILS;
                                       break;
       case 'log_actual_hours_segment':this.activeTab = ProjectDetailsConstants.LOG_ACTUAL_HOURS;
                                       break;
       case 'overview_segment': this.activeTab = ProjectDetailsConstants.PROJECT_ITEMS_VIEW;
                                break;
    }
    console.log('Active Tab Value',this.activeTab);
  }


  // GET SERVICE LEVEL ROADMAPS LIST
  getServiceLevelConfiguredRoadmap(serviceRoadmaps: any[]) {
    if (isNotEmptyArray(serviceRoadmaps)) {
      this.sharedService.selectedServiceRoadmapsList = serviceRoadmaps;
      //const buckets = bucketListInfo.filter(_bli => isNotEmptyArray(_bli.machines))
      this.serviceRoadmaps = serviceRoadmaps;
    } else {this.serviceRoadmaps = [];}
  }

  // GET MACHINE LEVEL CONFIGURED ROADMAPS
  configureRoadmap(event, configureLevel) {
    this.openConfigureRoadmapPopUp(event, configureLevel);
  }


  // OPEN POP OVER FOR SELECTING PRE-DEFINED OR NEW ROADMAP
  async openConfigureRoadmapPopUp(ev, type) {
    const props = { type };
    const popover = await this.popController.create({
      component: StatusPopoverComponent,
      cssClass: 'my-4-eye-custom-css',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false,
      // mode: 'ios',
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
      const { data } = result;
      if (data && data == 'custom_roadmap') {this.createCustomRoadmapInServiceLevel();}
      else if (data && data == 'predefined_roadmap') {this.openPredefinedRoadmapAlertModal([]);}//this.getPredefinedRoadMap()
    });
  }

  // OPEN DIALOG BOX WITH PREDEFINED ROADMAP LIST
  async openPredefinedRoadmapAlertModal(predefinedLists) {
    const props = { predefinedRoadmaps: predefinedLists, configurelevel: 'service level' };
    const modal = await this.modalController.create({
      component: AddPredefinedRoadmapsComponent,
      cssClass: 'add-remove-widget-class',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { selectedPredefinedRoadmapLists } } = await modal.onWillDismiss();
    if (isNotEmptyArray(selectedPredefinedRoadmapLists)) {this.storeSelectedPredefinedRoadmap(selectedPredefinedRoadmapLists);}
  }

  // SAVING SELECTED PREDEFINED ROADMAPS
  storeSelectedPredefinedRoadmap(predefinedRoadmapLists) {
    if (isNotEmptyArray(predefinedRoadmapLists)) {
      const selectedRoadmapsList = [];
      predefinedRoadmapLists.forEach(eachRoadmap => {
        const dataObj = {
          id: eachRoadmap.id,
          roadmapMasterId: eachRoadmap.id,
          roadmapName: eachRoadmap.roadmapName,
          roadMapVersion: '',
          isCustom: eachRoadmap.isCustom,//false,
          roadmapStatus: eachRoadmap.roadmapStatus,
          isCustomRoadmap: false,
          // RoadmapNameChanged: "true",
          RoadmapNameChanged: false,
          markAsComplete: false,
          machineLimits: {}
        };
        selectedRoadmapsList.push(dataObj);
      });
      const payload = {
        serviceId: this.selected_service_Id,
        projectId: this.projectList.project_Id,
        isTemplate: true,
        roadmaps: selectedRoadmapsList
      };
      this.backendService
        .configureServiceRoadmap(payload)
        .subscribe(
          (data: any) => {
            data == 'Roadmap Configuration Successful' && this.getServiceRoadmapsList(this.project_Id, this.service_Id);
          },
          (err) => {
            console.log('getting issue in storing selected predefined roadmap :', err);
          }
        );
    }
  }

  // TO FETCH SERVICE LEVEL CONFIGURED ROADMAPS
  getServiceLevelConfiguredroadmaps() {
    const serviceId = this.selected_service_Id ? this.selected_service_Id : this.service_Id;
    const projectId = this.projectList.project_Id ? this.projectList.project_Id : this.project_Id
    this.backendService.getServiceLevelRoadmap(serviceId, projectId)
      .then(
        (data: any) => {
          this.sharedService.selectedServiceRoadmapsList = data;
          this.sharedService.selectedServiceRoadmapsList = this.sharedService.selectedServiceRoadmapsList.map(item => {
            const currentStatus = item.roadmapStatus;
            switch (currentStatus) {
              case 'In Progress':
                return { ...item, status:currentStatus, backgroundColor: '#f2db7c' };
              case 'Completed':
                return { ...item,status:currentStatus, backgroundColor: '#71eb8d' };
              case 'Not Started':
                return { ...item,status:currentStatus, backgroundColor: '#cccccc' };
              default:
                return { ...item,status:currentStatus, backgroundColor: '#97B5FA'};
            }
          });
          this.sharedService.selectedServiceRoadmapsList.push(...this.sharedService.seviceCustomRoadmapsList);
          this.checkRoadMapStatus();
        },
        (err) => {
        }
      );
  }

  // CHECKING ROADMAP STATUS
  checkRoadMapStatus() {
    const listsOfRoadMapStatus = this.sharedService.getRoadMapStatusLists();
    if (isNotEmptyArray(listsOfRoadMapStatus) && isNotEmptyArray(this.sharedService.selectedServiceRoadmapsList)) {
      listsOfRoadMapStatus.forEach(_ls => {
        this.sharedService.selectedServiceRoadmapsList.forEach(_sl => {
          if (_sl.id == _ls.roadMapId) {_sl.status = _ls.status ? _ls.status : 'Not Started';}
        });
      });
    }
  }

  // CREATING CUSTOM ROADMAP IN SERVICE LEVEL
  async createCustomRoadmapInServiceLevel() {
    const props = { configureLevel: 'service level' };
    const modal = await this.modalController.create({
      component: AddCustomRoadmapsComponent,
      cssClass: 'mango-alert-modal',
      backdropDismiss: false,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
      if (result && isNotEmptyArray(result.data)) {this.saveServiceCustomRoadmapInfo(result.data);}
    });
  }

  // SAVING CUSTOM ROADMAP IN SERVICE LEVEL
  saveServiceCustomRoadmapInfo(roadmapLists) {
    if (isNotEmptyArray(roadmapLists)) {
      roadmapLists.forEach(element => {
        const data = {
          roadmapName: element,
          roadmapStatus: 'Not Started',
          isCustom: true,
          id: ''
        };
        this.sharedService.selectedServiceRoadmapsList.push(data);
        this.sharedService.seviceCustomRoadmapsList.push(data);
        this.toastService.presentToast('Custom roadmap created successfully.', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      });
    }
  }

  ionViewWillEnter() {
    this.isRoadmapGridDisplayed = true;
    this.getServiceTypeOnCreateService();
    this.selectServiceOrderOptions = [];
    document.addEventListener('updateMachineListInfo', (ev: CustomEvent) => {
      ProjectManagementLayoutPage.searchServiceData.subscribe((res) => {
        this.getMachineListsInfo(res.machineListInfo);
        this.getServiceLevelConfiguredRoadmap(res.serviceRoadmaps);
      });
    });
    // this.getAllUsersInfo()
    this.checkPermissions();
    document.addEventListener('APICallMachineList', (ev: CustomEvent) => {
      this.getServiceIdWithDetails();
      if (ev.detail
       && ev.detail.service_Id !== undefined
       && ev.detail.project_Id !== undefined) {
        this.getMachineListsInfoDetails(ev.detail.order_Id, ev.detail.service_Id, ev.detail.project_Id);
        this.getBucketListInfoDetails(ev.detail.project_Id, ev.detail.order_Id, ev.detail.service_Id);
      }else {
        this.getMachineListsInfoDetails(null, this.selectedServiceIdNonSAP, this.projectList.project_Id);
      }
    });
    document.addEventListener('APICallEditProject', (ev: CustomEvent) => {
      if (ev.detail) {
        this.getSearchProDetails(ev.detail.project_Id);
      }
    });

    document.addEventListener('APICallServiceRoadmapList', (ev: CustomEvent) => {
      if (ev.detail) {
        this.selected_service_Id = ev.detail.service_Id;
        this.project_Id = ev.detail.project_Id;
        if (ev.detail.service_Id) {
          this.service_Id = ev.detail.service_Id;
        }
        this.getServiceRoadmapsList(this.project_Id, this.service_Id);
      }
    });
    document.addEventListener('getServiceIds', (ev: CustomEvent) => {
      const getServiceIdsListener = () => {
          this.getServiceIdWithDetails();
          document.removeEventListener('getServiceIds', getServiceIdsListener);
      };
      getServiceIdsListener();
    });
  }

  ngAfterViewInit(){
    if(!this.isFromSAP){
      this.getServiceIdWithDetails();
    }
  }

  // DELETING ROADMAP CONFORMATION ALERT PROMPT
  public async deleteRoadmapConfirmation(roadmap) {
    const title = AppConstants.ARE_YOU_SURE_TO_DELETE;
    const actionText = AppConstants.DELETE;
    const props = {
      confirmationTitle: title,
      actionText
    };
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { isActionConfirmed } } = await modal.onWillDismiss();
    if (isActionConfirmed) {
      this.deleteRoadmap(roadmap); 
    }
  }

  // DELETING ROADMAP
  deleteRoadmap(roadmap) {
    const payload = {
      serviceMachineId: this.selected_service_Id ? this.selected_service_Id : this.service_Id,
      roadmapId: roadmap.id,
      isCustom: roadmap.isCustom,
      projectId: this.projectList.project_Id
    };
    if (!roadmap.isCustom) {
      this.sharedService.isGridDataLoading = true;
      this.backendService.deleteRoadmap(payload).subscribe((res: any) => {
        this.getServiceRoadmapsList(this.project_Id, this.service_Id);        
        this.toastService.presentToast('Roadmap Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }, err => {
        this.toastService.presentToast( 'No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
        this.sharedService.isGridDataLoading = false;
        this.fnInitialiseRoadmapGrid();    
      });
    } if (roadmap.isCustom && roadmap.id !== '') {
      this.sharedService.isGridDataLoading = true;
      this.backendService.deleteRoadmap(payload).subscribe((res: any) => {
        this.getServiceRoadmapsList(this.project_Id, this.service_Id);
        this.toastService.presentToast('Roadmap Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }, err => {
        this.toastService.presentToast( 'No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top'); 
        this.sharedService.isGridDataLoading = false;
        this.fnInitialiseRoadmapGrid(); 
      });

    }
    // else {
    //   this.sharedService.selectedRoadmapsList.splice(index, 1);
    //   this.toastService.presentToast('Roadmap Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
    // }
  }

  // OPEN SERVICE ROADMAP
  openRoadMap(roadmap) {
    if (roadmap.roadmapStatus != 'Completed' && roadmap.roadmapStatus != 'completed') {
      roadmap.serviceMachineId = roadmap?.serviceMachineId;
      roadmap.serviceId = this.selected_service_Id;
      const navigationExtras: NavigationExtras = {
        queryParams: roadmap,
        queryParamsHandling: 'merge'
      };
      this.router.navigate([window.location.pathname, 'service-roadmaps', this.project_id], navigationExtras);
    }
  }

  // FETCHING PROJECT SERVICE ORDER ID'S LIST
  getProServiceOrder(projectId) {
    const payload = projectId;
    this.backendService
      .getProjecDetailOrderService(payload)
      .then((res) => {
        const tmpServiceOrder = [];
        if (isNotEmptyArray(res)) {
          res.forEach((_od) => { if (_od.erp_Order_Id) {tmpServiceOrder.push({ id: _od?.id, erp_Order_Id: _od.erp_Order_Id });} });
        }
        this.selectServiceOrderOptions = [];
        this.selectServiceOrderOptions = [...tmpServiceOrder];
      });
  }

  // FETCHING PROJECT DETAILS
  async getSearchProDetails(projectId) {
    this.isviewFromSearch = true;
    const payload = projectId;
    return await this.backendService
      .getprojectDetail(payload)
      .then((res: ProjectDetails) => {
        this.projectList = res;
        this.isFromSAP = res.is_sap;
        this.assignedStaffList = [];
        res.assignedStaff.forEach((element) => {
          this.assignedStaffList.push({
            firstName: element.first_Name,
            LastName: element.last_Name,
            userRole: element.user_Role,
            emailAdd: element.email_Address,
            displayName: element.display_Name
          });
        });
        this.enddatelocal = moment(this.projectList.end_Date).format('MM/DD/YYYY HH:mm:ss');
        if (this.projectList && this.projectList.project_Id) {
          this.project_id = this.projectList.project_Id;
          this.projectList.project_Id = this.projectList.project_Id;
          this.sharedService.erp_project_id = this.projectList.erp_Project_Id;
        }
        if (!isObjectEmpty(this.projectList.customerDetails))
          {this.projectList.customerDetails.address =
            this.combineFullAddress(this.projectList.customerDetails);}

      });
  }


  // FUNCTION TO CHECK RBAC PERMISSIONS
  checkPermissions() {
    const user = this.userAuth.getUserList();
    if (user && isNotEmptyArray(user.permissions)) {
      const { permissions } = user;
      this.isBucketListPermission = (permissions.includes('Project.Manage') || permissions.includes('Project.List') || permissions.includes('Project.Read'));
      this.isBucketWritePermission = (permissions.includes('Project.Manage') || permissions.includes('Project.Write'));
      this.isBucketDeletePermission = (permissions.includes('Project.Manage') || permissions.includes('Project.Delete'));
      this.isMachineListPermission = (permissions.includes('Project.Manage') || permissions.includes('Project.Write') || permissions.includes('Project.List') || permissions.includes('Project.Read') || permissions.includes('Service.Manage') || permissions.includes('Service.Write') || permissions.includes('Service.List') || permissions.includes('Service.Read'));
      this.isMachineReadPermission = (permissions.includes('Project.Manage') || permissions.includes('Project.Read') || permissions.includes('Service.Manage') || permissions.includes('Service.Read'));
      this.isOfferWritePermission = (permissions.includes('Offer.Manage') || permissions.includes('Offer.Write'));
      this.isConflictResolutionPermission = (permissions.includes('Conflict.Manage'));
    }
  }

  goBack() {
    if (this.pageFrom  != undefined && this.pageFrom == 'orderMang') {
      this.router.navigateByUrl('/order-management');
    } else if (this.pageFrom == 'dashboard'){
      this.router.navigateByUrl('/dashboard');
    } else {
      this.router.navigateByUrl('/project-management');
    }
    sessionStorage.removeItem('prjDetailSegment');
    sessionStorage.removeItem(StorageKeys.ASSIGNED_STAFF);
  }

  // FETCHING PROJECT SERVICE TYPES BY SENDING ORDER ID TO API
  getServiceTypeByOrderId(orderId) {
    this.getProjectServiceTypeLoading = true;
    this.serviceTypesList = [];
    this.backendService.getServiceTypeByOrderId(orderId)
      .subscribe(
        (data: ServiceDetailsList) => {
          this.serviceTypesList = data;
          this.getProjectServiceTypeLoading = false;
        },
        (err) => {
          this.getProjectServiceTypeLoading = false;
        }
      );
  }

  // FUNCTION TO ADD NEW MACHINE POPOVER
  async openAddMachinePopover(ev: any, type) {
    const each_service = {
      serviceType: '',
      page: 'projectDetails',
      project_Id: this.project_id,
      order_Id: this.prodetailsData.selectOrder,
      service_Id: this.selectedServiceIdNonSAP || this.selected_service_Id,
      customer_Name: this.projectList.customer_Name,
      customer_id: this.projectList.erp_Customer_Id,
    };
    sessionStorage.setItem('existingMachines', JSON.stringify(this.existingMachineIds));
    each_service.serviceType = this.selectedServiceIdNonSAP || this.selected_service_Id;
    const props = { type, each_service };
    const popover = await this.popoverController.create({
      component: StatusPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false,
      // mode: 'ios',
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
    });
  }

  async initializeProjectDetailScreen() {
    this.getProjectDetail();
    this.getProjectServiceOrder();
  }

  // FETCHING PROJECTS BASIC DETAILS
  getProjectDetail() {
    ProjectManagementLayoutPage.projectDetails.subscribe((res) => {
      if (!isObjectEmpty(res)) {
        this.assignedStaffList = [];
        res.companyCodeAndCompanyName = res.company_Code + ' - ' + res.comapny_Name;
        this.projectList = res;
        res.assignedStaff.forEach(element => {
          this.assignedStaffList.push({
            firstName: element.first_Name,
            LastName: element.last_Name,
            userRole: element.user_Role,
            emailAdd: element.email_Address,
            displayName: element.display_Name
          });
        });
        sessionStorage.setItem(StorageKeys.ASSIGNED_STAFF, JSON.stringify(this.assignedStaffList))
        this.enddatelocal = moment(this.projectList.end_Date).format('MM/DD/YYYY HH:mm:ss');
        if (this.projectList && this.projectList.project_Id) {
          this.project_id = this.projectList.project_Id;
          // this.projectList.project_Id = this.projectList.project_Id
          this.sharedService.erp_project_id = this.projectList.erp_Project_Id;
        }
        if (!isObjectEmpty(this.projectList.customerDetails))
          {this.projectList.customerDetails.address =
            this.combineFullAddress(this.projectList.customerDetails);}
      }
    }, err => {
      this.toastService.presentToast( 'No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');  
    });
  }

  // FETCHING PROJECT SERVICE ORDER ID'S LIST FROM MAIN LAYOUT
  getProjectServiceOrder() {
    ProjectManagementLayoutPage.projectServiceDetails.subscribe((res) => {
      if (isNotEmptyArray(res)) {
        const tmpServiceOrder = [];
        if (isNotEmptyArray(res)) {
          res.forEach((_od) => { if (_od.erp_Order_Id) {tmpServiceOrder.push({ id: _od.id, erp_Order_Id: _od.erp_Order_Id });} });
        }
        this.selectServiceOrderOptions = [];
        this.selectServiceOrderOptions = [...tmpServiceOrder];
      }
    });
  }

  // FUNCTION TO OPEN DASHBOARD CUSTOMIZATION POPOVER
  openDashboardCustomization(isDashboardCustomize) {
    const msg = `Are You Sure You Want to Reset to default widgets?`;
    this.openDashBoardCustimazitionAlertModal(msg, isDashboardCustomize);
  }

  // FUNCTION TO OPEN DASHBOARD CUSTOMIZATION POPOVER
  async openDashBoardCustimazitionAlertModal(msg, isDashboardCustomize = false) {
    const props = { isDashboardCustomize };
    props['alertContent'] = msg;
    const modal = await this.modalController.create({
      component: AlertModalPage,
      cssClass: 'mango-dashboard-customization-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
  }

  // TO COMBINE FULL ADDRESS
  combineFullAddress(address) {
    let fullAddress = null;
    if (!isObjectEmpty(address)) {
      const { street, city, zipcode } = address;
      fullAddress = `${street},${city}, ${zipcode}`;
    }
    return fullAddress;
  }

  // FUNTION TO UPDATE SELECTED SERVICE TYPE
  selectServiceType(ev: any) {
    this.prodetailsData.serviceId = ev.id ? ev.id : ev.serviceId;
    this.prodetailsData.serviceType = ev.service_Type ? ev.service_Type : ev.service_type;
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: { serviceType: ev.service_Type, serviceId: ev.id }
    };
    this.router.navigate(window.location.pathname.split('/'), navigationExtras);

    const matchedservice = this.serviceTypesList.find(_st => _st.service_Type == ev.service_Type);
    if (matchedservice) {this.selected_service_Id = matchedservice.id;}
    if ((this.prodetailsData.serviceId == '')) {
      this.IsEmptyCard = false;
    } else {
      if (
        !isObjectEmpty(this.prodetailsData) ||
        this.prodetailsData.selectOrder ||
        this.prodetailsData.serviceId
      ) {
        this.getServiceAndMachineListInfo(
          this.prodetailsData.selectOrder,
          this.prodetailsData.serviceId,
          this.projectList.project_Id
        );
      }
    }
  }

  // async getServiceAndMachineListInfo(orderId, serviceType, projectId) {
  //   // this.router.navigate([window.location.pathname], { queryParams: { orderId, serviceType, projectId }, replaceUrl: true });
  // }

  // GET SERVICE AND MACHINES DATA BY CALLING HTTPS CALLS
  async getServiceAndMachineListInfo(orderId, serviceType, projectId) {
    await this.getServiceListInfoDetails(orderId, serviceType, projectId);
    await this.getProjectBudgetInfo(orderId, serviceType, projectId);
    this.isMachineListPermission && await this.getMachineListsInfoDetails(orderId, serviceType, projectId);
    await this.getOverviewInfoDetails(orderId, serviceType, projectId);
    this.isBucketListPermission && this.getBucketListInfoDetails(projectId, orderId, serviceType);
    this.project_Id = projectId;
    if (serviceType) {
      this.service_Id = serviceType
    }
    await this.getServiceRoadmapsList(this.project_Id, this.service_Id);
  }


  // FETCH SERVICE UI TAB DETAILS
  getServiceListInfo(res) {
    if (!isObjectEmpty(res)) {
      this.serviceTabData = this.sharedService.mapServicesListInfo(res);
      if (!isObjectEmpty(this.serviceTabData)) {
        if (
          this.prodetailsData.serviceId !==
          'Training On-Site' &&
          this.prodetailsData.serviceId !==
          'Training Off-Site'
        )
          //   this.shouldShowMachineAndOverviewService = true;
          // else this.shouldShowMachineAndOverviewService = false;
          {this.IsEmptyCard = true;}
      }
    }
  }

  // ON REFRESH FETCH MACHINE DETAILS
  refreshMachineListsInfo(orderId, serviceType, projectId) {
    this.backendService.getProjectDetailMachineListsInfo(orderId, serviceType, projectId, false).then(res => {
      if (isNotEmptyArray(res)) {
        this.machineListInfo = [...res];
        this.constructLogActualHoursData();
      }
    }).catch((err) => {
      console.log('getting error for fetching project detail machine info:', err);
    });
  }

  refreshProjectsListInfo(projectId) {
    sessionStorage.removeItem(StorageKeys.ASSIGNED_STAFF);
    this.assignedStaffList = [];
    this.backendService.getprojectDetail(projectId).then(res => {
      res.assignedStaff.forEach(element => {
        this.assignedStaffList.push({
          firstName: element.first_Name,
          LastName: element.last_Name,
          userRole: element.user_Role,
          emailAdd: element.email_Address,
          displayName: element.display_Name
        });
      });
    sessionStorage.setItem(StorageKeys.ASSIGNED_STAFF, JSON.stringify(this.assignedStaffList));
    }).catch((err) => {
    });
  }

  getMachineListsInfo(res: any[]) {
    this.machineListInfo = [];
    if (isNotEmptyArray(res)) {
      this.machineListInfo = [...res];
      this.constructLogActualHoursData();
      this.statusStepperData = this.sharedService.fedExStatusStepperData;
    }
  }


  getProjectBudgetInfo(orderId, serviceType, projectId) {
    this.backendService.getProjectBudgetInfo(projectId, serviceType, orderId).subscribe((res: BudgetAndCostData) => {
      if (res) {
        this.budgetInfo = res;
      }
    }, err => {
      this.toastService.presentToast( 'No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');  
    });
  }

  getServiceListInfoDetails(orderId, serviceType, projectId) {
    if (orderId && serviceType && projectId) {
      return this.backendService
        .getProjectDetailServiceListsInfo(orderId, serviceType, projectId)
        .then((res) => {
          if (!isObjectEmpty(res)) {
            this.serviceTabData = this.sharedService.mapServicesListInfo(res);
            if (!isObjectEmpty(this.serviceTabData)) {
              if (
                this.prodetailsData.serviceId !==
                'Training On-Site' &&
                this.prodetailsData.serviceId !==
                'Training Off-Site'
              )
                //   this.shouldShowMachineAndOverviewService = true;
                // else this.shouldShowMachineAndOverviewService = false;
                {this.IsEmptyCard = true;}
            }
          }
        })
        .catch((err) => {
          console.log(
            'getting error for fetching order detail service info:',
            err
          );
          if (err && err.error == 'No Services Found') {
            this.IsEmptyCard = true;
            this.serviceTabData = {};
          } else {this.IsEmptyCard = false;}
        });
    }
  }

  getMachineListsInfoDetails(orderId, serviceType, projectId) {
    if (orderId || serviceType && projectId) {
      return this.backendService
        .getProjectDetailMachineListsInfo(orderId, serviceType, projectId, false)
        .then((res) => {
          this.machineListInfo = [];
          this.existingMachineIds = [];
          if ((res)) {
            this.machineListInfo = [...res];
            console.log(this.machineListInfo );
            this.machineListInfo.filter((data)=>{
              this.existingMachineIds.push(data.machine_Id);
            });
            if (this.machineListInfo.length > 0) {
              this.isMachineAvailable = true;
            } else {
              this.isMachineAvailable = false;
            }
       
            console.log(this.existingMachineIds,'this.existingMachineIds');            
            this.isConflictInAnyMachine = this.machineListInfo.some(_mli => _mli.serviceMachine_Id.isConflict);
            this.constructLogActualHoursData();
            this.statusStepperData = this.sharedService.fedExStatusStepperData;
          }
        })
        .catch((err) => {
          console.log('getting error for fetching project detail machine info:', err);
        });
    }
  }

  getOverviewInfoDetails(orderId, serviceType, projectId) {
    if (orderId && serviceType && projectId) {
      return this.backendService
        .getOverViewListsInfo(orderId, serviceType, projectId).then((res: ProjectsOverviewList) => {
          if ((res)) { this.projectOverViewLists = res; 
            
          }
          return this.projectOverViewLists;
        }).catch(err => {
          console.log('getting issue in fetching overview info :', err);
          return [];
        });
    }
  }

  constructLogActualHoursData() {
    if (isNotEmptyArray(this.machineListInfo)) {
      this.logActualHoursInfo = [];
      this.logActualHoursInfo = this.sharedService.constructLogActualHoursLists(this.machineListInfo);
    } else {
      this.logActualHoursInfo = [];
    }
  }

  // FINC SELECT JOB ORDER ID
  selectJobOrderId(ev: any) {
    this.prodetailsData.selectOrder = ev.erp_Order_Id;
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: {
        orderId: ev.erp_Order_Id
      }
    };
    this.router.navigate(window.location.pathname.split('/'), navigationExtras);
  }

  changeMachineSegment(type) {
    if (type == 'my-machine') {this.machineSegmentType = type;}
    else if (type == 'team-machine') {this.machineSegmentType = type;}
    else {this.machineSegmentType = type;}
  }

  changeMachineListView(type) {
    if (type == 'layout') {this.machineListViewType = 'layout-view';}
    else {this.machineListViewType = 'grid-view';}
  }

  changeAllSelectedMachineValue() {
    if (this.machineSegmentType == 'all-machine') {
      this.allMachineListsInfo.forEach(_almi => _almi.isChecked = this.isAllMachineSelected);
    } else if (this.machineSegmentType == 'my-machine') {
      this.myMachineLists.forEach(_ml => {
        _ml.isChecked = false;
        this.changeMachineTypeCheckedValue(_ml);
      });
    }
  }

  checkMyMachieAllCheckboxSelectedOrnot(mymachineInfo) {
    if (this.machineSegmentType == 'all-machine') {
      const matchedM = this.allMachineListsInfo.find(_mml => _mml.bucketId == mymachineInfo.bucketId);
      if (matchedM && isNotEmptyArray(matchedM.machineInfo)) {
        const eCh = matchedM.machineInfo.every(_mmi => _mmi.isChecked);
        if (eCh) {matchedM.isChecked = eCh;}
        const eUc = matchedM.machineInfo.every(_mmi => !_mmi.isChecked);
        if (eUc) {matchedM.isChecked = eCh;}
        let anyMachineSelected = false;
        this.allMachineListsInfo.forEach(_amli => {
          const anySelected = _amli.machineInfo.some(_mi => _mi.isChecked);
          if (anySelected) {anyMachineSelected = true;}
        });
        this.isAnyMachineListSelected = anyMachineSelected;
        const al = this.allMachineListsInfo.some(_amlif => _amlif.isChecked);
        if (!al) {this.isAllMachineSelected = al;}
      }
    } else if (this.machineSegmentType == 'my-machine') {
      const matchedM = this.myMachineLists.find(_mml => _mml.machineType == mymachineInfo.machineType);
      if (matchedM && isNotEmptyArray(matchedM.machineInfo)) {
        const eCh = matchedM.machineInfo.every(_mmi => _mmi.isChecked);
        if (eCh) {matchedM.isChecked = eCh;}
        const eUc = matchedM.machineInfo.every(_mmi => !_mmi.isChecked);
        if (eUc) {matchedM.isChecked = eCh;}
      }
    }
  }

  changeMachineTypeCheckedValue(mymachine) {
    if (this.machineSegmentType == 'all-machine') {
      if (mymachine && mymachine.machineType) {
        const matchedMyMachine = this.allMachineListsInfo.find(_mml => _mml.bucketId === mymachine.bucketId);
        if (matchedMyMachine && matchedMyMachine.bucketId && isNotEmptyArray(matchedMyMachine.machineInfo)) {
          matchedMyMachine.machineInfo.forEach(_mmminfo => _mmminfo.isChecked = matchedMyMachine.isChecked);
        }
      }
    } else if (this.machineSegmentType == 'my-machine') {
      if (mymachine && mymachine.machineType) {
        const matchedMyMachine = this.myMachineLists.find(_mml => _mml.machineType === mymachine.machineType);
        if (matchedMyMachine && matchedMyMachine.machineType && isNotEmptyArray(matchedMyMachine.machineInfo)) {
          matchedMyMachine.machineInfo.forEach(_mmminfo => _mmminfo.isChecked = matchedMyMachine.isChecked);
        }
      }
    }
  }

  changeAllSelectedCheckboxValue() {
    this.machineListInfo.forEach(_mli => _mli.isChecked = this.isAllMachineCheckboxSelected);
    this.isAnyMachineListSelected = this.machineListInfo.some(_mli => _mli.isChecked);
    if (this.machineListViewType == 'layout-view') {this.changeMachineCheckboxSelection();}
  }

  changeMachineCheckboxSelection(machineList?) {
    this.isAnyMachineListSelected = this.machineListInfo.some(_mli => _mli.isChecked);
    if (!this.isAnyMachineListSelected) {this.isAllMachineCheckboxSelected = this.isAnyMachineListSelected;}
  }

  // FETCHING BUCKETS DATA
  getBucketListInfo(bucketListInfo: BucketData[]) {
    if (isNotEmptyArray(bucketListInfo)) {
      //const buckets = bucketListInfo.filter(_bli => isNotEmptyArray(_bli.machines))
      this.allBucketLists = bucketListInfo;
    } else {this.allBucketLists = [];}
  }

  //FETCHING SERVICE ROADMAPS DATA
  getServiceRoadmapListInfo(serviceRoadmap: []) {
    if (isNotEmptyArray(serviceRoadmap)) {
      this.sharedService.selectedServiceRoadmapsList = serviceRoadmap;
    } else {
      this.sharedService.selectedServiceRoadmapsList = [];
    }
  }

  async getBucketListInfoDetails(projectId, orderId, serviceType) {
    const bucketListInfo = await this.getBucketInfo(projectId, orderId, serviceType);
    if (isNotEmptyArray(bucketListInfo)) {
      //const buckets = bucketListInfo.filter(_bli => isNotEmptyArray(_bli.machines))
      //this.allBucketLists = buckets
      this.allBucketLists = bucketListInfo;
    } else {this.allBucketLists = [];}
  }

  async getServiceRoadmapsList(projectId, serviceType) {
    let serviceRoadmapsList = [];   
    return this.backendService.getServiceLevelRoadmap(serviceType, projectId)//this.selected_service_Id
      .then(res => {        
        if (isNotEmptyArray(res.response)) {
          serviceRoadmapsList = res.response;          
          this.sharedService.selectedServiceRoadmapsList = res.response;
          this.sharedService.selectedServiceRoadmapsList = this.sharedService.selectedServiceRoadmapsList.map(item => {
            const currentStatus = item.roadmapStatus;
            switch (currentStatus) {
              case 'In Progress':
                return { ...item, status:currentStatus, backgroundColor: '#f2db7c' };
              case 'Completed':
                return { ...item,status:currentStatus, backgroundColor: '#71eb8d' };
              case 'Not Started':
                return { ...item,status:currentStatus, backgroundColor: '#cccccc' };
              default:
                return { ...item,status:currentStatus, backgroundColor: '#97B5FA'};
            }
          });
          setTimeout(() => {
            this.gridComponent?.refreshGridData();
          }, 1000);
          return serviceRoadmapsList;
        } else {
          this.sharedService.selectedServiceRoadmapsList = [];
          this.sharedService.isGridDataLoading = false;
          this.gridComponent.fnAppendAllDataInGrid(this.sharedService.selectedServiceRoadmapsList);
          this.fnInitialiseRoadmapGrid();
        }
      }).catch(err => {
        console.log('error in getting service Roadmap info :', err);
        this.sharedService.isGridDataLoading = false;
        this.fnInitialiseRoadmapGrid();
        return serviceRoadmapsList;
      });
  }

  async addToBucket(shouldCreateOnlyNewBucket = false, shouldEditBucket = false) {
    let bucketInfo = [];
    const { selectOrder, serviceType } = this.prodetailsData;
    if (!shouldCreateOnlyNewBucket) {bucketInfo = await this.getBucketInfo(this.project_id, selectOrder, serviceType);}
    const props = { bucketLists: bucketInfo, shouldCreateOnlyNewBucket, shouldEditBucket };
    const modal = await this.modalController.create({
      component: AddMachineToBucketComponent,
      cssClass: 'add-machine-to-bucket-class',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { bucketName, bucketId, shouldAddToBucket, shouldConstructAndAddToBucket, isBucketUpdated, updatedBucketLists, deletedBucketLists } } = await modal.onWillDismiss();
    //for adding some machine to bucket by creating new bucket and also to create new bucket only
    if (bucketName && (shouldAddToBucket || shouldConstructAndAddToBucket)) {await this.addBucketNameOrAddMachineToBucket(bucketName, !shouldAddToBucket);}
    //if (bucketName && !shouldAddToBucket) this.constructMyMachineSegmentData(bucketName)

    //adding machine to already existing bucket
    if (bucketId && (!shouldAddToBucket && !shouldConstructAndAddToBucket)) {this.addMachineToExistingBucket(bucketId);}
    if (isBucketUpdated && isNotEmptyArray(updatedBucketLists)) {await this.editBucketLists(updatedBucketLists);}
    if (isBucketUpdated && isNotEmptyArray(deletedBucketLists)) {this.deleteBucketLists(deletedBucketLists);}
  }

  getBucketInfo(projectId?, orderId?, serviceType?) {
    let bucketList: BucketData[] = [];
    if (this.selected_service_Id) {
      return this.backendService.getBucketLists(projectId, orderId, serviceType, this.selected_service_Id)
        .then((res: BucketDetails) => {
          if (isNotEmptyArray(res.Data)) {bucketList = res.Data;}
          return bucketList;
        }).catch(err => bucketList);
    }
  }

  addMachineToExistingBucket(bucketId) {
    const selectedMachineIds = [];
    if (bucketId && isNotEmptyArray(this.machineListInfo)) {
      const allMachineInfo = JSON.parse(JSON.stringify(this.machineListInfo));
      allMachineInfo.forEach(_am => { if (_am.isChecked) {addUniqueEntry(selectedMachineIds, _am.machine_Id);} });
      this.createBucketOnlyOrCreateBucketAndAddMachineOrAddMachineToExistingBucket('', bucketId, selectedMachineIds, false, true);
    }
  }

  addBucketNameOrAddMachineToBucket(bucketName, shouldCreateNewBucketAndAddMachine = false) {
    if (bucketName) {
      const selectedMachineIds = [];
      //when creating new bucket and adding machine into it
      if (shouldCreateNewBucketAndAddMachine) {
        const allMachineInfo = JSON.parse(JSON.stringify(this.machineListInfo));
        allMachineInfo.forEach(_am => { if (_am.isChecked) {addUniqueEntry(selectedMachineIds, _am.machine_Id);} });
      }
      this.createBucketOnlyOrCreateBucketAndAddMachineOrAddMachineToExistingBucket(bucketName, '', selectedMachineIds, shouldCreateNewBucketAndAddMachine);
    }
  }

  createBucketOnlyOrCreateBucketAndAddMachineOrAddMachineToExistingBucket(bucketName, bucketId, machineIds = [],
    shouldCreateNewBucketAndAddMachine = false, isAddidngToExistingBucket = false) {
    const { selectOrder, serviceType } = this.prodetailsData;
    const bucketListInfo = this.sharedService.initializaCreateBucketList(bucketName, bucketId, this.project_id, selectOrder, machineIds, this.selected_service_Id);
    //if (isAddidngToExistingBucket) { delete (bucketListInfo.project_Id); delete (bucketListInfo.order_Id) }
    if (isAddidngToExistingBucket) {this.addMachinesToExistingBucket(bucketId, machineIds);}
    else {this.createBucketOnlyOrCreateBucketAndAddMachines(bucketName, bucketListInfo, shouldCreateNewBucketAndAddMachine, isAddidngToExistingBucket);}
  }

  addMachinesToExistingBucket(bucketId, machineIds = []) {
    if (bucketId && isNotEmptyArray(machineIds)) {
      const bucketListInfo = this.sharedService.initializeAddMachineToExistingBucket(bucketId, machineIds);
      return this.backendService.addMachineToExistingBucket(bucketListInfo).then(res => {
        if (res && res.Data) {
          const msg = `Machines added Successfully.`;
          msg && this.toastService.presentToast(msg, 4000, AppConstants.SUCCESS_SNACKBAR, 'top'); 
          this.machineListInfo.forEach(_mli => { if (_mli.isChecked) {_mli.isChecked = false;} });
        }
      }).catch(err => {
        console.log('getting issue in adding machine to existing bucket :', err);
      });
    }
  }

  createBucketOnlyOrCreateBucketAndAddMachines(bucketName, bucketListInfo, shouldCreateNewBucketAndAddMachine = false, isAddidngToExistingBucket = false) {
    const { selectOrder, serviceType } = this.prodetailsData;
    return this.backendService.createNewBucket(bucketListInfo).then(res => {
      if (res && res.Data) {
        let msg = '';
        if (isAddidngToExistingBucket) {msg = `Machines added Successfully.`;}
        else {
          if (shouldCreateNewBucketAndAddMachine) {msg = `${bucketName} Bucket Created and Machines added Successfully.`;}
          else if (res.StatusReason == 'Bucket created successfully.') {msg = `Bucket Created Successfully.`;}
        }
        msg && this.toastService.presentToast(msg, 4000, AppConstants.SUCCESS_SNACKBAR, 'top'); 

        this.makeAvailableMachineListToInitialStateAndFetchLatestBucket(this.project_id, selectOrder, serviceType, !shouldCreateNewBucketAndAddMachine);
        //if (shouldCreateNewBucketAndAddMachine || isAddidngToExistingBucket) this.makeAvailableMachineListToInitialStateAndFetchLatestBucket(this.project_id, selectOrder, serviceType);
      }
    }).catch(err => {
      this.toastService.presentToast(err.error.StatusReason, 4000, AppConstants.ERROR_SNACKBAR, 'top');
    });
  }

  makeAvailableMachineListToInitialStateAndFetchLatestBucket(projectId, orderId, serviceType, shouldNotUpdateAvailableMachine = false) {
    setTimeout(async () => {
      const bucketLists = await this.getBucketInfo();
      this.getBucketListInfo(bucketLists);
      !shouldNotUpdateAvailableMachine && this.machineListInfo.forEach(_mli => { if (_mli.isChecked) {_mli.isChecked = false;} });
    }, 3 * 1000);
  }

  async editBucketLists(bucketData) {
    if (isNotEmptyArray(bucketData)) {
      const { selectOrder, serviceType } = this.prodetailsData;
      const bucketBody = await this.sharedService.initialiizeEditBucketInfo(bucketData, this.project_id, selectOrder, this.selected_service_Id);
      if (isNotEmptyArray(bucketBody)) {
        this.backendService.editBuckets(bucketBody).then(res => {
          if (res && res.Data) {
            const { selectOrder, serviceType } = this.prodetailsData;
            this.toastService.presentToast(`Bucket Edited Successfully.`, 4000, AppConstants.SUCCESS_SNACKBAR, 'top')
            this.makeAvailableMachineListToInitialStateAndFetchLatestBucket(this.project_id, selectOrder, serviceType, true);
          }
        }).catch(err => {
          console.log('getting issue in edit bucket :', err);
        });
      }
    }
    return true;
  }

  async deleteBucketLists(bucketData) {
    if (isNotEmptyArray(bucketData)) {
      const bucketBody = await this.sharedService.initializeDeleteBucketInfo(bucketData);
      if (isNotEmptyArray(bucketBody)) {
        this.backendService.deleteBuckets(bucketBody).then(res => {
          if (res && res.Data) {
            const { selectOrder, serviceType } = this.prodetailsData;
            this.toastService.presentToast(`Bucket Deleted Successfully.`, 4000, AppConstants.SUCCESS_SNACKBAR, 'top')
            this.makeAvailableMachineListToInitialStateAndFetchLatestBucket(this.project_id, selectOrder, serviceType, true);
          }
        }).catch(err => {
          console.log('getting issue in deleting the bucket :', err);
        });
      }
    }
  }

  myMachineAddBucket(event, type) {
    this.openStatusPopover(event, type);
  }


  async openStatusPopover(ev: any, type, projectStatus?, machineList?, bucket?) {
    const props = { type };
    if (projectStatus) { props['projectStatus']  = projectStatus;}
    if (machineList) {
      props['isFourEyeRequested'] = machineList?.serviceMachine_Id?.isRequested || false;
      props['is_RA_Status'] = machineList?.is_RA_Status || false;
      props['isFourEyeQualityPerform'] = machineList?.fourEyeQualityDTO?.isFourEyePerform || false;
      // props['isFourEyeRequested'] = machineList
    }
    if (type == 'machineSegmentBuckesEditOrCreate') {
      props['isCreateBucketPermission'] = this.isBucketWritePermission;
      props['isUpdateBucketPermission'] = this.isBucketWritePermission;
      props['isDeleteBucketPermission'] = this.isBucketDeletePermission;
    }
    // if (type == 'projectInformationStatus') {
    //   props['isCreateBucketPermission'] = this.isBucketWritePermission;
    // }
    const popover = await this.popController.create({
      component: StatusPopoverComponent,
      cssClass: (type == '4_eyeQualityCheck' || type == 'machine-specific-kebab-elipse' || (type == 'bucket-machine-specific-kebab-elipse'))
        ? 'my-4-eye-custom-css' : 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false,
      // mode: 'ios',
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
      const { data } = result;
      if (data == 'request-4-eye-quality-check') {this.requestFourEyeQualityCheck(machineList);}
      else if (data == 'perform-4-eye-quality-check') {
        this.performFourEyeQuality(machineList);
      }
      else if (data == 'assignProject') { console.log('delete project'); }
      else if (data == 'create' || data == 'edit') {this.createOrEditBucket(data);}
      else if (data == 'editProj') {this.navigateToEditProject();}
      else if (data == 'deleteProj') { console.log('delete project'); }
      else if (data == 'blockProj') { console.log('block project'); }
      else if (data == 'closeProj') { console.log('close project'); }
      else if (data == 'edit-machine') {
        machineList.serviceId = this.selected_service_Id;
        machineList.offer_id = this.serviceTabData.offerId;
        machineList.pageName = 'projectEditEachMachine';
        const macchineData = {
          id: machineList.machine_Id,
          machine_name: machineList.machine_Name,
          machine_type: machineList.machine_Type,
          description: machineList.machine_Description,
          asset_id: machineList.asset_Id,
          serial_number: machineList.serial_Number,
          estimated_hours: machineList.estimated_Hours,
          master_machine_id: machineList.masterMachine_Id
        };
        machineList.machines = [];
        machineList.machines.push(macchineData);
        this.editMachine(machineList);
      }
      else if (data == 'delete-Bucket') {
        this.deleteMachine(machineList, bucket);
      } else if (data && data == 'save_to_template') {
        this.saveToTemplate(projectStatus);
      }
    });
  }

  saveToTemplate(roadmap) {
    if (roadmap.id) {
      this.backendService.getServiceRoadmapSteps(roadmap.id, this.userAuth.getUserList().userId, this.selected_service_Id, this.projectList.project_Id).subscribe(
        (data: any) => {
          const payload = {
            roadMapMasterId: '',
            serviceMachineId: this.selected_service_Id,
            projectRoadMapId: roadmap.id,
            roadMapName: roadmap.roadmapName,
            roadMapVersion: '',
            description: '',
            createdAt: new Date().toISOString(),
          };
          let roadMapSections;
          roadMapSections = JSON.parse(JSON.stringify(data.section))?.map((each: RoadMapSection) => ({
              roadMapSectionId: '',
              roadMapMasterId: '',
              sectionName: each.sectionName,
              roadMapSubSection: (each?.subSections || []).map((subSection: RoadMapSubSection) => ({
                  roadMapSubSectionId: '',
                  roadMapSectionId: '',
                  subSectionName: subSection.subSectionName,
                  stepMaster: (subSection?.steps || []).map((step: StepMaster) => ({
                      roadMapSubSectionId: '',
                      stepMasterId: '',
                      stepBody: step.stepBody,
                    }))
                }))
            }));
            payload['roadMapSections'] = roadMapSections;
          setTimeout(() => {
            this.backendService.saveToTemplate([payload])
              .subscribe(
                (data: any) => {
                  this.toastService.presentToast(data.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
                }, err => {
                  this.toastService.presentToast(this.translate.instant('no_results_found'), 4000, AppConstants.ERROR_SNACKBAR, 'top');
                }
              );
          }, 100);
        }, err => {
          this.toastService.presentToast(this.translate.instant('no_results_found'), 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    } else { }

  }

  editMachine(each_service) {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
    };
    const each_serviceData = each_service;
    // Store the parameter in sessionStorage
    sessionStorage.setItem('each_serviceData', JSON.stringify(each_serviceData));
    sessionStorage.setItem('editMachine', JSON.stringify(each_service.machines));
    this.router.navigate(['offer-management/edit-machines'], navigationExtras);
  }

  /**
   *
   * @param eachActualHour
   */
  editActualHours(eachActualHour: ActualHours) {
    eachActualHour.edit = !eachActualHour.edit;
    this.islogActualHoursEditing = !this.islogActualHoursEditing;
    if (isNotEmptyArray(this.logActualHoursListsToEdit)) {this.logActualHoursListsToEdit = [];}
    if (this.islogActualHoursEditing && this.logActualHoursInfo.length == 0) {
      const dummyMachine = {
        serviceMachineId: '',
        machine_Id: '00000000-0000-0000-0000-000000000000',
        machine_id: '00000000-0000-0000-0000-000000000000',
        calculatedHours: 0,
        assetNo: 0,
        aggregateHours: 0,
        actualHours: 0,
        comments: '',
        serialNo: '',
        machinename: ''
      };
      this.logActualHoursInfo.push(dummyMachine);
    }
    this.logActualHoursInfo.forEach(_lahi => { if (_lahi.isActualHourExceedError) {delete _lahi.isActualHourExceedError;} });
  }

  /**
   * Fnc open mavhine Accordion
   *
   * @param eachActualHour
   */
  openMachineDetails(eachActualHour) {
    eachActualHour.open = !eachActualHour.open;
    eachActualHour.edit = !eachActualHour.edit;
    const user = this.userAuth.getUserList();
    this.backendService.getLogActualHoursList(eachActualHour.serviceMachineId, user.userId).subscribe(
      (data: ActualHoursList) => {
        if (data) {
          data.forEach((each: ActualHours) => {
            each.userName = user.name;
            each.edit = false;
            each.calculatedHours = eachActualHour.calculatedHours;
            each.machine_id = eachActualHour.machine_id;
          });
          this.actualHoursList = data;
        }
      }, err => {

      }
    );
  }

  navigateToEditProject() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: { createProject: true, projectId: this.projectList?.project_Id }
    };
    this.router.navigate(['/project-management'], navigationExtras);
  }

  requestFourEyeQualityCheck(machineList) {
    this.openAddUserModal('assign-4-eye-quality-check-user', machineList);
  }

  performFourEyeQualityCheck(machineList) {
    const { machine_Id, machine_Name, serviceMachine_Id: { serviceMachineId } } = machineList;
    const currentUrl = this.router.url;
    const spilitedUrlData = currentUrl.split('/');
    let encrypted_id = '';
    const encrpId = spilitedUrlData[3];
    if (encrpId.includes('?')) {encrypted_id = encrpId.substring(0, encrpId.indexOf('?'));}
    else {encrypted_id = spilitedUrlData[3];}
    const url = `${spilitedUrlData[1]}/${spilitedUrlData[2]}/${encrypted_id}/four-eye-quality/${machine_Id}/${serviceMachineId}/${machine_Name}`;
    const navigationExtras: NavigationExtras = {
      queryParams: {
        serviceType: this.serviceTabData?.serviceType,
      }
    };
    this.router.navigate([url], navigationExtras);
    //this.router.navigateByUrl(`/${url}`);
  }

  performFourEyeQuality(machineList) {
    if (this.projectId == '' || this.projectId == undefined || this.projectId == null) {
      this.performFourEyeQualityCheck(machineList);
    }
    else {
      const { machine_Id, machine_Name, serviceMachine_Id: { serviceMachineId } } = machineList;
      const currentUrl = this.router.url;
      const spilitedUrlData = currentUrl.split('/');
      let encrypted_id = '';
      const encrpId = spilitedUrlData[1];
      const encrpId1 = encrpId.slice(33);
      if (encrpId.includes('?')) {encrypted_id = encrpId.substring(0, encrpId.indexOf('?'));}
      else {encrypted_id = spilitedUrlData[3];}
      const url = `project-management/project-detail/${encrpId1}/four-eye-quality/${machine_Id}/${serviceMachineId}/${machine_Name}`;
      const navigationExtras: NavigationExtras = {
        queryParams: {
          serviceType: this.serviceTabData?.serviceType,
        }
      };
      this.router.navigate([url], navigationExtras);
      // this.router.navigateByUrl(`/${url}`);
    }

  }

  goToMachineDetails(event, machine) {
    if (this.isMachineReadPermission) {
      this.getGroups(machine);
      this.sharedService.selectedServiceRoadmapsList = [];
      this.sharedService.seviceCustomRoadmapsList = [];
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'merge',
        queryParams: {
          machineId: machine.machine_Id,
          project_id: this.project_id,
          orderId: this.orderId,
          serviceId: this.selected_service_Id || this.selectedServiceIdNonSAP,
          serviceMachineId: machine.serviceMachine_Id.serviceMachineId
        },
      };
      if (this.isviewFromSearch) {
        this.getGroups(machine);
        const url = `/dashboard/project-detail/${this.project_id}/machine-details/${machine.machine_Id || machine.Machine_Id}`;
        this.router.navigate([url], navigationExtras);
      } else {
        this.router.navigate([window.location.pathname, 'machine-details', machine.machine_Id || machine.Machine_Id], navigationExtras);
      }

    } else {
      this.toastService.presentToast('Sorry you do not have the access to view machines.', 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
  }

  getGroups(machine) {
    this.graphService.groupId = '';
    this.graphService.siteId = '';
    this.backendService.getSiteDetails(this.projectList.erp_Project_Id || this.project_id + '_' + this.projectList.company_Code).subscribe((data: any) => {
      if (data) {
        data.forEach(element => {
          if (element.sitename == (this.projectList.erp_Project_Id || this.project_id + '_' + this.projectList.company_Code)) {//res.erp_Project_Id + "_" + res.company_Code
            this.graphService.groupId = element.siteid;
            this.graphService.getSiteId().subscribe((data: any) => {
              this.graphService.siteId = data.parentReference.siteId;
              // let url = `/dashboard/project-detail/${this.project_id}/machine-details/${machine.machine_Id || machine.Machine_Id}`
              // this.router.navigate([url], navigationExtras);
              this.isviewFromSearch = false;
            });
          }
        });
      }
    }, err => {
      this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    });
  }


  async deleteMachine(machineList, bucket) {
    const bucketIdMachineId = bucket.Bucket_Id + '/' + machineList.serviceMachine_Id.serviceMachineId;
   // const loader = await this.presentLoading('Please wait...');
    this.backendService.deleteBucketMachines(bucketIdMachineId).subscribe(
      (response: any) => {
      //  loader && loader.dismiss();
        if (response.StatusReason == 'Machine deleted from bucket successfully.') {
          this.toastService.presentToast('Delete Machine Succesfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
          this.changeServiceTypeSelection(bucket);
        }
      },
      error => {
        (err) => { };
      });
  }

  async presentLoading(msg) {
    const loading = await this.loading.create({
      cssClass: 'my-custom-loading',
      message: msg,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }


  getAllUsersInfo() {
    return this.backendService.getAllUsers().then(_user => {
      const { Data } = _user;
      if (isNotEmptyArray(Data)) {
        // this.userManagementAllUserInfo = Data;
        // this.bk_userManagementAllUserInfo = JSON.parse(JSON.stringify(this.userManagementAllUserInfo));
      }
    }).catch(err => {
      console.log('Issue in fetching all users:', err);
    });
  }

  getUserList() {
    const user = {
      name: '',
      userId: '',
      email: '',
      roles: []
    };
    const userInfo = this.getUserInfoFromLocal();
    if (userInfo) {
      user.name = userInfo.name;
      user.userId = userInfo.userId;
      user.email = userInfo.email;
      user.roles = userInfo.roleAssignmentInfo;
    }
    // this.UserId = user.userId;
    return user;

  }

  getUserInfoFromLocal() {
    return this.localSettingsSrv.getuserInfo('userLists');
  }

  goToConflictResolutionPage() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
    };
    if (this.isConflictResolutionPermission && this.isConflictInAnyMachine) {
      this.router.navigate([window.location.pathname, 'conflict-resolution'], navigationExtras);
    }
  }

  deleteAssignedStaff(user) {
    if (this.isBucketDeletePermission) { }
    else {
      this.toastService.presentToast('You do not have the access to delete assigned staff. Please contact admin.', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    }
  }

  deleteAssignedUser(contact) {
    const payload = {
      project_id: this.project_id,
      user_id: contact.emailAdd,
    };
    this.backendService.deleteAssignUser(payload).subscribe((response: any) => {
      if (response == 'Owner of the project cannot be modified') {
        this.sharedService.errorShowToast('', response);
      } else if (response == 'Staff Unassigned') {
        this.toastService.presentToast('User is deleted successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
        this.refreshProjectsListInfo(this.project_id);
      }
      else {
        this.toastService.presentToast('Failed to delete User', 4000, AppConstants.ERROR_SNACKBAR, 'top');        
      }

    }, err => {
      this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');    
    }
    );
  }

  goToAddAssignedStaff(type) {
    if (this.isBucketWritePermission) {this.openAddUserModal(type);}
    else {
      this.toastService.presentToast('You do not have the access to assign staff. Please contact admin.', 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
  }

  async openAddUserModal(type, machineList?) {
    const props = { type, assignedStaffList: this.assignedStaffList };
    const modal = await this.modalController.create({
      // commented component can be used to see assign staff UI
      //SearchWithMultiSelectCheckboxComponent,
      component: SearchWithMultiSelectCheckboxComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: false,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
      const { data: { groupName, newRole, roleToAssign, modeType, selectedUsers } } = result;
      if (modeType == 'assign-4-eye-quality-check-user' && isNotEmptyArray(selectedUsers)) {
        this.storeFourEyeRequestedDetails(machineList.serviceMachine_Id.serviceMachineId, selectedUsers);

      }
      if (modeType == 'user_console_add_user' && isNotEmptyArray(selectedUsers)) {
        this.addNewUsers(selectedUsers);
      };
      if(type == 'assignProject' && isNotEmptyArray(selectedUsers)) {
        selectedUsers.forEach((element) => {
          this.UserIdAssign = element.Id,
          this.addMemberToProject(element);
        });
      }
      if (result.data?.userIdValue?.length == 1) {
        this.UserIdAssign = result.data.userIdValue[0].Id,
        this.addMemberToProject(result.data.userIdValue[0]);
      }
    });
  }

  async addMemberToProject(user) {
    let groupDetails: any = {};
    const groupList = await this.backendService.getSiteDetails(this.projectList.erp_Project_Id || this.project_id + '_' + this.projectList.company_Code);
    groupList.subscribe(async (res: any) => {
      groupDetails = res.filter((element: any) => element.sitename == (this.projectList.erp_Project_Id || this.project_id + '_' + this.projectList.company_Code));
      if (groupDetails) {
        await this.graphService.getUSerByUserPrincipleName(user.UserEmail).subscribe(async (res) => {
          if (res.id) {
            const paylaod = {
              '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${res.id}`
            };
            if (groupDetails) {
              this.addProjectUser(res.id, user.Id);
              await this.graphService.addMemberToSite(groupDetails[0].siteid, paylaod).subscribe(() => {
                
              }, err => {
                if (err?.error?.error?.message) {
                  this.toastService.presentToast(err.error.error.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
                } else {
                   this.toastService.presentToast('Failed to add staff to project', 4000, AppConstants.ERROR_SNACKBAR, 'top');
                }
              });
            } else {
              this.addProjectUser('', '');
            }
          }
        }, (err) => {
          this.errorToast();
          this.addProjectUser('', '');
        });
      } else {
        this.toastService.presentToast('Failed to add staff to project', 4000, AppConstants.ERROR_SNACKBAR, 'top');        
      }
    });


  }

  async errorToast() {
    const toast = await this.toastController.create({
      header: '',
      message: 'Member not added to share point site',
      cssClass: 'my-custom-error-toast',
      duration: 7000,
      position: 'top',
    });
    toast.present();
  }

  async deleteMemberToProject(UserEmail, contact) {
    let groupDetails = [];
    const groupList = await this.backendService.getSiteDetails(this.projectList.erp_Project_Id || this.project_id + '_' + this.projectList.company_Code);
    groupList.subscribe((res: any) => {
      groupDetails = res.filter((element: any) => element.sitename == (this.projectList.erp_Project_Id || this.project_id + '_' + this.projectList.company_Code));
    });
    if (groupDetails.length > 0) {
      this.graphService.getUSerByUserPrincipleName(UserEmail).subscribe((res) => {
        if (res.id) {
          this.graphService.deleteMemberToSite(groupDetails[0].siteid, res.id).subscribe(() => {
            this.deleteAssignedUser(contact);
          }, err => {
            if (err.error?.error?.message) {
              this.toastService.presentToast(err.error?.error?.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }
          });
        }
      }, err => {
        this.deleteAssignedUser(contact);
      });
    } else {
      this.toastService.presentToast('Failed to delete staff from project', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    }
  }

  addProjectUser(userSharePointId, userId) {
    const payload = {
      project_id: this.project_id,
      user_ids: [
        {
          user_id: userId,
          userSharePointId
        }
      ]
    };
    this.backendService.addNewUserProj(payload)
      .subscribe(
        (data: any) => {
          if (data = 'Staff has been added to project') {
            this.toastService.presentToast(data, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
            this.refreshProjectsListInfo(this.project_id);
          }
        },
        (err) => {
        }
      );
  }

  async openAddUserFlo(type) {
    const props = { type };
    const modal = await this.modalController.create({
      component: SearchModalComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: true,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
    });
  }

  addNewUsers(users) {
    const usersInfo = this.sharedService.initialzeNewUsersToAdd(users);
    this.backendService.addNewUsersToAd(usersInfo).then(res => {
      if (res && res.Data && res.StatusCode == 200) {
        this.toastService.presentToast('User Added Successfully.', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
        setTimeout(() => { this.getAllUsersInfo(); }, 3000);
      }
    }).catch(err => {
      console.log('Getting issue in adding user to ad :', err);
    });
  }

  async storeFourEyeRequestedDetails(serviceMachineId, selectedUsers) {
    if (serviceMachineId && isNotEmptyArray(selectedUsers)) {
      const user = selectedUsers[0];
      const requestedFourEyePayload = await this.sharedService.initializeRequestedFourEyeInformation(serviceMachineId, user.Id);
      if (requestedFourEyePayload && requestedFourEyePayload.reviewer && requestedFourEyePayload.serviceMachine_Id) {
        this.backendService.requestFourEyeQualityInfo(requestedFourEyePayload).then(res => {
          if (res) {
            this.toastService.presentToast('Requested 4 Eyes Quality Check', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
            setTimeout(() => {
              const { selectOrder, serviceType } = this.prodetailsData;
              this.refreshMachineListsInfo(selectOrder, serviceType, this.projectList?.project_Id);
              // temp solution: find way to refresh module without reloading;
              window.location.reload();
            }, 500);
          }
        }).catch(err => {
          console.log('getting issue in requesting Four Eye Quality :', err);
        });
      }
    }
  }

  createOrEditBucket(mode) {
    if (mode && mode == 'create') {this.addToBucket(true);}
    else if (mode && mode == 'edit') {this.addToBucket(false, true);}
  }

  machineMoreIcons(event, type, machineList, bucket?) {
    // To prevent the propagation of current event i.e. click on more icon on the card
    event.stopPropagation();
    this.openStatusPopover(event, type, '', machineList, bucket);
  }

  async showFilter(ev, type) {
    const each_service = {
      bucketTypes: [
        { bucketType: 'My Buckets' },
        { bucketType: 'Team Buckets' }
      ],
      assignee: [],
      assetNumber: [],
      machineManufacturer: [],
      machineType: [],
      serialNumber: [],
    };
    const popover = await this.popController.create({
      component: FilterMoodalComponent,
      cssClass: 'my-filtercustom-class',
      event: ev,
      componentProps: { filterFor: type, each_service },
      translucent: true,
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
      if (!isObjectEmpty(result) && isNotNullAndUndefined(result.data)) {
        const { data: { data: { bucketList, machineAssignee, assetNumber, manufacturer, machineType, serialNumber } } } = result;
      }
    });
  }

  changeServiceTypeSelection(bucketInfo) {
    this.allBucketLists.forEach(_aml => { if (_aml.Bucket_Id != bucketInfo.Bucket_Id) {_aml.opened = false;} });
    if (!bucketInfo.opened) {this.getMachinesAddedToBucket(bucketInfo);}
    else {bucketInfo.opened = !bucketInfo.opened;}
  }

  async getMachinesAddedToBucket(bucketInfo) {
    if (bucketInfo && isNotNullAndUndefined(bucketInfo.Bucket_Id) && isNotBlank(bucketInfo.Bucket_Id)) {
      this.backendService.getMachinesAddedToBucket(bucketInfo.Bucket_Id).then((res: BucketMachines) => {
        if (res && res.Data) {
          bucketInfo.machines = res.Data.Machines;
          bucketInfo.opened = !bucketInfo.opened;
        }
      }).catch(err => {
        console.log('getting issue in edit bucket :', err);
      });
    }
  }

  changeLogActualHoursEditingValue() {
    this.islogActualHoursEditing = !this.islogActualHoursEditing;
    if (isNotEmptyArray(this.logActualHoursListsToEdit)) {this.logActualHoursListsToEdit = [];}
    if (this.islogActualHoursEditing && this.logActualHoursInfo.length == 0) {
      const dummyMachine = {
        serviceMachineId: '',
        machine_Id: '00000000-0000-0000-0000-000000000000',
        machine_id: '00000000-0000-0000-0000-000000000000',
        calculatedHours: 0,
        assetNo: 0,
        aggregateHours: 0,
        actualHours: 0,
        comments: '',
        serialNo: '',
        machinename: ''
      };
      this.logActualHoursInfo.push(dummyMachine);
    }
    this.logActualHoursInfo.forEach(_lahi => { if (_lahi.isActualHourExceedError) {delete _lahi.isActualHourExceedError;} });

  }

  /**
   * Fnc Add New Row to tbale
   *
   * @param eachActualHour
   */
  public addNewRow(eachActualHour) {
    const user = this.userAuth.getUserList();
    const data: ActualHours = {
      id: '',
      serviceMachine_Id: eachActualHour.serviceMachineId,
      actualHours: 0,
      comments: '',
      machine_id: eachActualHour.machine_id,
      createdAt: new Date().toISOString(),
      userName: user.name,
      edit: true,
      calculatedHours: eachActualHour.calculatedHours,
      isActualHourExceedError: false,
      enableShowWarning: false
    };
    this.actualHoursList.push(data);
  }

  /**
   *
   * @param event
   * @param logActulaList
   * @param mode
   */
  public editLogActualHoursValues(event, logActulaList, mode) {
    if (event.detail.value == 0) {
      logActulaList.enableShowWarning = false;
    }
    if (event.detail.value) {
      const localLogHoursLists = JSON.parse(JSON.stringify(logActulaList));
      const matechLogInfo = this.logActualHoursInfo.find(_lahi => _lahi.machine_id === localLogHoursLists.machine_id);
      if (mode == 'edit-actual' && (event.detail.value > matechLogInfo.calculatedHours) || (event.detail.value > matechLogInfo.aggregateHours)) {
        logActulaList.isActualHourExceedError = true;
        logActulaList.enableShowWarning = true;
      } else if (mode == 'edit-actual' && logActulaList.isActualHourExceedError) {delete logActulaList.isActualHourExceedError;}
      if (matechLogInfo) {
        if (mode == 'edit-actual') {localLogHoursLists.newActualHours = event.detail.value;}
        else if (mode == 'edit-comment') {localLogHoursLists.newComments = event.detail.value;}
        if (isNotEmptyArray(this.logActualHoursListsToEdit)) {
          const matchedLogListToEdit = this.logActualHoursListsToEdit.find(_lahle => _lahle.machine_id == logActulaList.machine_id);
          if (matchedLogListToEdit) {
            if (mode == 'edit-actual') {matchedLogListToEdit.newActualHours = event.detail.value;}
            if (mode == 'edit-comment') {matchedLogListToEdit.newComments = event.detail.value;}
          }
          else {this.logActualHoursListsToEdit.push(localLogHoursLists);}
        } else {this.logActualHoursListsToEdit.push(localLogHoursLists);}
      }
    } else {
      if (isNotEmptyArray(this.logActualHoursListsToEdit)) {
        const matchedEditInfo = this.logActualHoursListsToEdit.find(_lahi => _lahi.machine_id === logActulaList.machine_id);
        if (matchedEditInfo && (mode == 'edit-actual' && !matchedEditInfo.newComments) || (mode == 'edit-comment' && !matchedEditInfo.newActualHours)) {
          const index = this.logActualHoursListsToEdit.findIndex(_lahi => _lahi.machine_id === logActulaList.machine_id);
          this.logActualHoursListsToEdit.splice(index, 1);
        } else {
          if (matchedEditInfo) {
            if (mode == 'edit-actual') {matchedEditInfo.newActualHours = event.detail.value;}
            else if (mode == 'edit-comment') {matchedEditInfo.newComments = event.detail.value;}
          }
        }
      }
    }
  }

  /**
   * Fnc saveLogActualHours
   *
   * @param eachActualHour
   * @param type
   */
  public saveLogActualHours(eachActualHour, type: string) {
    if (isNotEmptyArray(this.logActualHoursListsToEdit) && type == 'add') {
      this.logActualHoursInfo.forEach(_lahi => {
        if (_lahi.isActualHourExceedError) {delete _lahi.isActualHourExceedError;}
        if (_lahi.serviceMachineId == eachActualHour.serviceMachineId) {
          _lahi.open = true;
        }
      });
      this.saveEditedLogActualHours(type);
    }
    this.islogActualHoursEditing = false;
  }

 /**
  * Fnc Delete Log Actual Hours
  *
  * @param eachActualHour
  * @param actualHours
  * @param type
  */
  public deleteLogActualHours(eachActualHour,actualHours,type: string) {
    const logActualHoursInfo = {
      project_Id: '',
      order_Id: '',
      service_Id: '',
      serviceMachine_Id:'',
      user_Id: '',
      user_Name: '',
      machineActualHours: []
    };
    const user = this.userAuth.getUserList();
    logActualHoursInfo.project_Id = this.projectList?.project_Id;
    logActualHoursInfo.order_Id = this.prodetailsData.selectOrder;
    logActualHoursInfo.service_Id = this.selected_service_Id;
    logActualHoursInfo.serviceMachine_Id = actualHours.serviceMachine_Id;
    logActualHoursInfo.user_Id = user.userId;
    logActualHoursInfo.user_Name = user.name;
    const machineData={
      id:actualHours.id,
      serviceMachine_Id: actualHours.serviceMachine_Id,
      machine_Id: actualHours.machine_id,
      actualHours: actualHours.actualHours,
      comments: actualHours.comments,
      action:type
    };
    logActualHoursInfo.machineActualHours.push(machineData);

    if (logActualHoursInfo) {
      this.backendService.updateLogActualHoursInfo(logActualHoursInfo).then(res => {
        if (res && res == 'Actual Hours Added') {
          this.toastService.presentToast('Log Actual Hours Edited Successfully.', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
          this.openMachineDetails(eachActualHour);
        }
      }).catch(err => {
        console.log('getting issue in updating log actual hours :', err);
      });
    }
  }


  /*Changed as per the latest payload for editing log actual hours*/
  saveEditedLogActualHours(type: string) {
    if (isNotEmptyArray(this.logActualHoursListsToEdit)) {
      const logActualHours = this.sharedService.initializeLogActualHours(this.projectList?.project_Id, this.prodetailsData.selectOrder, this.selected_service_Id, this.logActualHoursListsToEdit, type);
      if (logActualHours && logActualHours.project_Id && isNotEmptyArray(logActualHours.machineActualHours)) {
        this.backendService.updateLogActualHoursInfo(logActualHours).then(res => {
          if (res && res == 'Actual Hours Added') {
              this.toastService.presentToast('Log Actual Hours Edited Successfully.', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
              const { selectOrder, serviceType } = this.prodetailsData;
              this.refreshMachineListsInfo(selectOrder, this.selected_service_Id, this.projectList?.project_Id);
          }
        }).catch(err => {
          console.log('getting issue in updating log actual hours :', err);
        });
      }
    }
  }

  /**
   * Fnc replaces text with empty
   *
   * @param event
   */
  onInputChange(event: any) {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^0-9]/g, ''); // Replace non-numeric characters with an empty string
  }

  async openProjectAction(ev: any, type, task?) {
    const props = { type, projectStatus: this.projectList.status, isFromSAP: this.isFromSAP };
    const popover = await this.popController.create({
      component: StatusPopoverComponent,
      cssClass: 'popProReport',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false,
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
      if (result.data.isCreateOfferSeleted) {
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'merge',
          queryParams: {
            project_id: this.project_id,
            pageName: 'ProjectAction'
          }
        };
        this.sharedService.listOfServices = [];
        this.router.navigate(['offer-management/create-offer'], navigationExtras);
      } else if (result.data.isProjectHistorySeleted) {
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'preserve',
        };
        this.router.navigate([window.location.pathname, 'view-project-history', this.project_id], navigationExtras);
      } else if(result.data.isCreateServiceSelected) {
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'merge',
          queryParams: {
            project_id: this.project_id,
            pageFrom: 'ProjectAction'
          }
        };
        this.router.navigate(['offer-management/create-offer/create-service'], navigationExtras);
        this.getServiceTypeOnCreateService();
      }
    });
  }

  getServiceTypeOnCreateService() {
    this.serviceTypesList = this.sharedService.viewOfferListOfServices;
  }

  async openProjReport(ev: any, type, task?: any, orderId?,
     productId?, projectId?, erp_project?, company_code?, project_Name?,
     isFromSAP?) {
    let generateProjectDisable;
    let machineStatus;
    for (const _bl of this.machineListInfo) {
      // this.machineListInfo.find(_bl => {
      if (_bl.is_RA_Status == true) {
        machineStatus = true;
        break;
      } else {
        machineStatus = false;
      }
      // });
    }
    if ((orderId != null && productId != null) || (!isFromSAP && productId != null)) {//&& machineStatus == true
      generateProjectDisable = false;
    } else {
      generateProjectDisable = true;
    }
    const props = {
      type, orderId, productId, generateProjectDisable,
      projectId, erp_Project_Id: this.projectList.erp_Project_Id,
      company_code, projectName: project_Name,
      serviceId: this.selectedServiceIdNonSAP
    };
    const popover = await this.popController.create({
      component: StatusPopoverComponent,
      cssClass: 'popProReport',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false,
      // mode: 'ios',
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
      //TODO
      // task['status'] = ''
      // if (task && result.data !== undefined) {
      //   typeof result?.data == 'string'
      //     ? (task.status = result?.data)
      //     : (task.taskManagerUsers = result?.data);
      // }
    });
  }

  async cancelModal() {
    let msg: string;
    this.translate.get(AppConstants.ARE_YOU_SURE_TO_CANCEL).subscribe(res => {
      msg = res;
    });
    const props = { alertContent: msg };
    const modal = await this.modalController.create({
      component: AlertModalPage,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { isConfirmed } } = await modal.onWillDismiss();
    isConfirmed && this.changeLogActualHoursEditingValue();
  }

  /**
   * Gets service id with details
   */
  public getServiceIdWithDetails() {
    this.backendService.getServiceIdWithDetails(this.encrypted_project_id).subscribe(
      (data: any) => {
        this.serviceIds = [];
        this.serviceIds = data.response.map(item => item.service_id);
        this.serviceDetailsOfNonSapProject = data.response;
        const savedSelectedServiceIdNonSAP = sessionStorage.getItem('selectedServiceIdNonSAP');
        this.selectedServiceIdNonSAP = savedSelectedServiceIdNonSAP 
          ? savedSelectedServiceIdNonSAP 
          : this.serviceDetailsOfNonSapProject[this.serviceDetailsOfNonSapProject.length - 1].service_id;
        sessionStorage.setItem('selectedServiceIdNonSAP', this.selectedServiceIdNonSAP);
        this.selected_service_Id = this.selectedServiceIdNonSAP;
        this.service_Id = this.selected_service_Id
        this.serviceDetails = this.serviceDetailsOfNonSapProject[this.serviceDetailsOfNonSapProject.length - 1];
        this.prodetailsData.serviceType = this.serviceDetails.service_type;
        console.log((this.serviceDetails?.is_machinebased == true || this.prodetailsData.serviceType==null || this.machineListInfo.length != 0 ))
        if(this.selectedServiceIdNonSAP) {
          this.getMachineListsInfoDetails(null, this.selectedServiceIdNonSAP, this.encrypted_project_id);
        }
      });
  }

  /**
   * Selects service id
   *
   * @param ev
   * @param selectedServiceIdNonSAP
   */
  public selectServiceId(ev, selectedServiceIdNonSAP) {
    this.selectedServiceIdNonSAP = selectedServiceIdNonSAP;
    this.segmentChanged('projectInformation_segment');
    sessionStorage.setItem('selectedServiceIdNonSAP', this.selectedServiceIdNonSAP);
    this.serviceDetailsOfNonSapProject.forEach(element => {
      if (element.service_id === ev) {
        this.serviceDetails = element;
      }
    });
    this.selected_service_Id = this.selectedServiceIdNonSAP;
    if(this.selected_service_Id) {
      this.getMachineListsInfoDetails(null, this.selected_service_Id, this.encrypted_project_id);
    }
    this.prodetailsData.serviceType = this.serviceDetails.service_type;
    this.customerSearchByErpId(this.serviceDetails.customer_id);
  }

  /**
   * To get the customer's contact data
   *
   * @param customer_id
   */
  private customerSearchByErpId(customer_id) {
    this.backendService
      .offerCustomerInfoSearchByErpId(customer_id)
      .subscribe(
        (data: any) => {
          data.contacts?.map((i) => { i.fullName = (i.first_Name != null ? i.first_Name : '') + ' ' + (i.last_Name != null ? i.last_Name : ''); return i; });
          this.sharedService.customersInfo.next(data);
        }, err => {
        }
      );
  }

  /**
   * To Edit service
   *
   * @param service
   * @param typeOfDetail
   */
  public editService(service, typeOfDetail) {
    if (typeOfDetail === 'serviceDetails') {
      service.isEditServiceShow = true;
    }
  }

  /**
   * To cancel editing the service
   *
   * @param service
   */
  public editServiceCancel(service) {
    service.isEditServiceShow = true;
    service.isEditServiceShow = !service.isEditServiceShow;
  }

  /**
   * Change contact person
   *
   * @param ev
   */
  public changeContactPerson(ev) {
    this.serviceDetails.contact_person = ev.display_Name;
    this.serviceDetails.contact_Id = ev.contact_Id;
  }

  /**
   * Change contact phone number
   *
   * @param ev
   */
  public changeContactPhone(ev) {
    this.serviceDetails.contact_phone_number = ev.phone_Number;
    this.serviceDetails.contact_Id = ev.contact_Id;
  }

  /**
   * Change contact email address
   *
   * @param ev
   */
  public changeContactEmail(ev) {
    this.serviceDetails.contact_email = ev.email_Address;
    this.serviceDetails.contact_Id = ev.contact_Id;
  }

  /**
   * To save service after edit
   *
   * @param service
   */
  public isEditServiceSave(service) {
    let editServicePayload;
    editServicePayload = {
      serviceId: this.selected_service_Id,
      contactId: this.serviceDetails.contact_Id,
      description: this.serviceDetails.service_description,
      startDate: this.convertDateFormat(this.serviceDetails.start_date),
      endDate: this.convertDateFormat(this.serviceDetails.end_date)
    };
    this.backendService.isEditOfferServiceSave(editServicePayload).subscribe(
      (data: any) => {
        if (data.value == 'Service updated successfully') {
          service.isEditServiceShow = false;
          this.toastService.presentToast( data.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
        }
      }, err => {
      });
  }

  /**
   * To convert date format further use to pass payload in edit service
   *
   * @param date
   * @returns
   */
  private convertDateFormat(date) {
    if (date) {
      const spilitedDate = date.split('/');
      return `${spilitedDate[2]}-${spilitedDate[1]}-${spilitedDate[0]}`;
    } else {return date;}
  }

    /**
     * Gets machine details
     *
     * @param projectId
     * @param serviceId
     */
    public getMachineDetails(projectId, serviceId) {
      this.backendService.getMachineDetails(projectId, serviceId).subscribe((res: any) => {
        if(res.statusCode == 200) {
            console.log(res);
            this.machineListInfo = res.response;
        }
      });
    }

    /**
     * Selected tab
     *
     * @param tabName
     */
    public selectedTab(tabName) {
      this.activeTab1 = tabName;
    }
  }
