import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ColumnApi, GridApi, GridOptions, IDatasource, IGetRowsParams, RowNodeTransaction } from 'ag-grid-community';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Subscription, fromEvent, of } from 'rxjs';
import { AddRemoveColumnSelectorComponent } from 'src/app/components/add-remove-column-selector/add-remove-column-selector.component';
import { StatusPopoverComponent } from 'src/app/components/status-popover/status-popover.component';
import { popoverMenu } from 'src/app/modals/popover-menu';
import { LocalSettingsService } from 'src/app/services/local-settings/local-settings.service';
import { isNotEmptyArray } from 'src/app/utilities/utils';
import { GridConfig } from '../../grid-config';
import { ActionsRendererComponent } from '../controls/actions-renderer/actions-renderer.component';
import { ButtonsRendererComponent } from '../controls/buttons-renderer/buttons-renderer.component';
import { FieldValueRendererComponent } from '../controls/field-value-renderer/field-value-renderer.component';
import { ProgressBarRendererComponent } from '../controls/progress-bar-renderer/progress-bar-renderer.component';
import { DateRendererComponent } from '../controls/date-renderer/date-renderer.component';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { CheckBoxRendererComponent } from '../controls/checkbox-renderer/checkbox-renderer.component';

@Component({
  selector: 'app-grid',
  templateUrl: './grid-component.html',
  styleUrls: ['./grid-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('parentComponentName')
  parentComponentName : string = '';

  @Input('columns')
  columnDefs: any[];

  @Input('data')
  allGridData: any;

  @Input('gridConfigItems')
  gridConfigItems: GridConfig;

  @Input('showKebabIcon')
  showKebabIcon = true;

  @Input('showHeader')
  showHeader = true;
  @Input() onLoadMore: (offset: number,searchText : string) => void;
  currentOffset: number = 0;
  hasMoreItems: boolean = true;
  debounceTimeout: any; // To store the timeout ID for debounce

  @Output()
  onCellClick = new EventEmitter<any>();

  @Output()
  onCheckAll = new EventEmitter<boolean>();

  @Output()
  onBtnClick = new EventEmitter<any>();

  gridData: any;
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridstyle: any;
  gridWrapperstyle: any = {
    width: '100%',
    height: '100%',
  };
  rowClassRules;
  gridlastRowIndex: number;
  private gridSortColumn: string;
  private gridSortOrder: string;
  private group_MIN_HEIGHT = 40;
  private header_MIN_HEIGHT = 40;
  private isAllChecked = false;
  searchText: string ='';
  filters: any = null;
  param: string;
  resizeSubscription$: Subscription;

  overlayLoadingTemplate: string;
  overlayNoRowsTemplate: string;
  isDataLoading = false;
  public loading= true;
  public shimmerGrid: number[] = Array(56).fill(0); // 8 rows * 7 columns = 56 cells
  isRefresh = false;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  pinnedBottomRowData: any;
  
  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  constructor(private translate: TranslateService,
    public popoverController: PopoverController,
    public modalController: ModalController,
    public sharedValue: ShareValuesService,
    public localSettingsSrv: LocalSettingsService) {
    this.overlayNoRowsTemplate = `
    <div class="text-center noDataAvailable w-100pc f-16 position-relative">
        ${this.translate.instant('no_data_availabe')}
      </div>
    `;
    this.overlayLoadingTemplate = `
    <div class="text-center noDataAvailable w-100pc f-16 position-relative">
        ${this.translate.instant('data_loading')}
      </div>
    `;
  }

  ngOnInit() {
    const resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = resizeObservable$.subscribe(evt => {
      this.setWidthAndHeight();
    });
    this.sharedValue.isGridDataLoading = true;
  }
  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }

  private setWidthAndHeight() {
    setTimeout(() => {
      const height = 400;
      this.gridstyle = {
        marginTop: '0px',
        width: '100%',
        height: (height) + 'px',
        boxSizing: 'border-box'
      };

    }, 100);
  }

  async openStatusPopover(ev: any) {
    let props: any;
    const type = this.gridConfigItems.menuType;
    switch (type) {
      case 1:
        props = { popoverMenu: popoverMenu.exportMenu };
        break;
      case 4:
        props = { popoverMenu: popoverMenu.gridMenu };
        break;


      default:
        break;
    }

    const popover = await this.popoverController.create({
      component: StatusPopoverComponent,
      cssClass: 'popover-position',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
      const { data } = result;
      if (data && data == 'export') {
        this.exportData();
      }
      if (data && data == 'add_remove') {
        switch(type)
        {
          case 4: this.openAddOrRemoveColumnSelectorAlertModal('projectStatus');
                  break;
        }
      }
    });
  }

  async openAddOrRemoveColumnSelectorAlertModal(type) {
    const props = { columnSelectorFor: type };
    const modal = await this.modalController.create({
      component: AddRemoveColumnSelectorComponent,
      cssClass: 'add-remove-widget-class',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const {
      data: {
        columnSelectorSelectedValue: {
          isDataChangedForColumnSelector,
          columnSelectorWidgetFor,
        },
      },
    } = await modal.onWillDismiss();
    if (isDataChangedForColumnSelector && columnSelectorWidgetFor) {this.getColumnSelectorPermission([columnSelectorWidgetFor]);}
  }

  getColumnSelectorPermission(types) {
    const _self = this;
    if (types.includes('projectStatus')) {
      const data =
        _self.localSettingsSrv.getProjectStatusColumnSelectorWidgetStorageValue();
      if (isNotEmptyArray(data))
        {_self.checkProjectTrackerColumnSelectorPermission(data);}
    }
  }
  projectTrackerColumnSelectorPermission: any = {};
  //FNC TO CHECK PERMISSIONS PROJECT TRACKER FIELDS
  checkProjectTrackerColumnSelectorPermission(value) {
    const _self = this;
    value.forEach((_v) => {
      if (_v.val === 'pid')
        {_self.projectTrackerColumnSelectorPermission.projectId =
          _v.isChecked;}
      if (_v.val === 'pname')
        {_self.projectTrackerColumnSelectorPermission.projectName =
          _v.isChecked;}
      if (_v.val === 'cname')
        {_self.projectTrackerColumnSelectorPermission.customerName =
          _v.isChecked;}
      if (_v.val === 'custId')
        {_self.projectTrackerColumnSelectorPermission.customerId =
          _v.isChecked;}
      if (_v.val === 'contactName')
        {_self.projectTrackerColumnSelectorPermission.contactName =
          _v.isChecked;}
      if (_v.val === 'custAddress')
        {_self.projectTrackerColumnSelectorPermission.customerAddress =
          _v.isChecked;}
      if (_v.val === 'rgns')
        {_self.projectTrackerColumnSelectorPermission.regions  = _v.isChecked;}
      if (_v.val === 'pmname')
        {_self.projectTrackerColumnSelectorPermission.projectManName =
          _v.isChecked;}
      if (_v.val === 'prg')
        {_self.projectTrackerColumnSelectorPermission.projectProgress  =
          _v.isChecked;}
      if (_v.val === 'hrPrg')
        {_self.projectTrackerColumnSelectorPermission.projectHourProgress =
          _v.isChecked;}
      if (_v.val === 'etd')
        {_self.projectTrackerColumnSelectorPermission.endDate = _v.isChecked;}
      if (_v.val === 'status')
        {_self.projectTrackerColumnSelectorPermission.status = _v.isChecked;}
    });
  }

  private sortGrid(sortColumn, sortDirection) {
    this.gridApi.applyColumnState({
      state: [
        {
          colId: sortColumn,
          sort: sortDirection
        }
      ],
      defaultState: {
        sort: null
      }
    });
  }

  public setDataLoading(isDataLoading) {
    this.isDataLoading = isDataLoading;
    if (this.gridlastRowIndex > 0) {
      this.isDataLoading = false;
      this.gridApi.hideOverlay();
    }
    else if (this.isDataLoading) {
      this.gridApi.showLoadingOverlay();
    }
    else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  public initializeGrid(isDataLoading = true) {
    const self = this;
    this.isDataLoading = isDataLoading;
    if (this.columnDefs && this.columnDefs.length > 0) {
      this.fnAddCheckboxColumn(this.columnDefs);

      this.overlayNoRowsTemplate = `
      <div class="text-center noDataAvailable w-100pc f-16 position-relative">
          ${this.translate.instant(this.gridConfigItems.noDataMessage)}
        </div>
      `;

      this.columnDefs.forEach(columnDef => {
        columnDef.tooltipComponent = 'customTooltip';
        columnDef.tooltipField = columnDef.field;
        if (!columnDef.headerComponentParams) {
          columnDef.headerComponentParams = {};
        }
        columnDef.headerComponentParams.rowCount = this.allGridData.length;
      });
      
      this.gridOptions = {
        rowSelection: 'multiple',
        rowHeight: self.gridConfigItems.rowHeight,
        headerHeight: 0,
        groupHeaderHeight: 0,
        cacheBlockSize: 8,
        maxBlocksInCache: 3,
        rowModelType: 'infinite',
        pagination: false,
        paginationAutoPageSize: true,
        alwaysShowHorizontalScroll: true,
        onBodyScroll: () => this.debounceScroll(),
        components: {
            progressBarRenderer: ProgressBarRendererComponent,
            buttonRenderer: ButtonsRendererComponent,
            actionRenderer: ActionsRendererComponent,
            fieldValueRenderer: FieldValueRendererComponent,
            dateRenderer:DateRendererComponent,
            cbxRenderer: CheckBoxRendererComponent
        },
        suppressCellFocus: self.gridConfigItems.suppressCellSelection,
        suppressRowClickSelection: self.gridConfigItems.suppressRowClickSelection,
        defaultColDef: {
          flex: 1,
          sortable: true,
          resizable: true,
          filter: 'agTextColumnFilter',
          autoHeight: true
        },
        tooltipShowDelay: 0,
        suppressRowTransform: true,
        rowClassRules: {
          disabled(params) { return params && params.data && params.data.isDisabled; },

          'cursor-pointer'(params) {
            return !self.gridConfigItems.suppressRowClickSelection;
          },
          'row-highlight'(params) {
            return params && params.data && params.data.isRowHighlighted;
          },
          'ag-row-highlight-even'(params) {
            if (params && params.data && params.data.isRowHighlighted) {
              return false;
            }
            else {
              if (params && params.data && !params.data.customeRowClass)
                {return params.node.rowIndex % 2 === 0;}
            }
          },
          'ag-row-highlight-odd'(params) {
            if (params && params.data && params.data.isRowHighlighted) {
              return false;
            }
            else {
              if (params && params.data && !params.data.customeRowClass)
                {return params.node.rowIndex % 2 != 0;}
            }
          },
        },
        columnDefs: this.columnDefs
      };
      this.setWidthAndHeight();
    }
  }

  public updateGridColumnDefinitions(columnDefs) {
    if (this.gridApi) {
      if (columnDefs && columnDefs.length > 0) {
        this.columnDefs = columnDefs;
        this.fnAddCheckboxColumn(columnDefs);
      }

      this.columnDefs.forEach(columnDef => {
        columnDef.tooltipComponent = 'customTooltip';
        columnDef.tooltipField = columnDef.field;
        if (!columnDef.headerComponentParams) {
          columnDef.headerComponentParams = {};
        }
        columnDef.headerComponentParams.rowCount = this.allGridData.length;
      });

      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.columnDefs);

    }
  }

  public fnAddCheckboxColumn(columnDefs) {
    const self = this;
    if (self.gridConfigItems.enableRowSelection) {
      console.log('In add checkbox column');
      if (!this.columnDefs.find(c => c.field == 'selected')) {
        const cbxColumn = {
          headerName: '',
          field: 'selected',
          cellRenderer: 'cbxRenderer',
          sortable: false,
          resizable: false,
          maxWidth: 60,
          pinned: true,
          headerComponentParams: {
            isCheckbox: true,
            isAllChecked: false,
            isCheckAllAllowed: self.gridConfigItems.isCheckAllAllowed,
            onCheckAll: (event) => this.onAllChecked(event.target.checked)
          },
          suppressMovable: true,
          lockPosition: true,
          lockPinned: true,
          tooltipField: 'selected',
        };
        this.columnDefs.unshift(cbxColumn);
      }
    }
  }

  public refreshGridData(newData?, hasMoreItems?) {
    this.overlayNoRowsTemplate = `
    <div class="text-center noDataAvailable w-100pc f-16 position-relative">
        ${this.translate.instant(this.gridConfigItems.noDataMessage)}
      </div>
    `;

    if (this.gridApi) {
      this.isDataLoading = false;
      this.isRefresh = true;

      // Capture current scroll position
      const gridBody = document.querySelector('.ag-body-viewport') as HTMLElement;
      const scrollTop = gridBody ? gridBody.scrollTop : 0;

      this.resetSortModel();
      this.resetDataSource();

      this.columnDefs.forEach(columnDef => {
        if (!columnDef.headerComponentParams) {
          columnDef.headerComponentParams = {};
        }
        columnDef.headerComponentParams.rowCount = this.allGridData.length;
      });

      this.updateGridColumnDefinitions(null);

      // Update grid data
      if (newData && hasMoreItems) {
        this.hasMoreItems = hasMoreItems;
        this.allGridData = [...newData];
        setTimeout(() => {
          if (gridBody) {
            gridBody.scrollTop = scrollTop;
          }
        }, 100);
      }
    } else {
      this.initializeGrid();
    }

    this.sharedValue.isGridDataLoading = false;
}

  public resetSortModel() {
    this.sortGrid(this.gridConfigItems.defaultSortColumn, this.gridConfigItems.defaultSortOrder);
  }

  public setSortModel(sortColumn, sortDirection) {
    this.sortGrid(sortColumn, sortDirection);
  }

  public fnAppendAllDataInGrid(gridDataForAppend) {
   
    this.allGridData = this.allGridData.concat(gridDataForAppend);
    this.allGridData = this.sortByKey(this.allGridData, this.gridSortColumn, this.gridSortOrder);
    if(this.allGridData.length == 0)
      this.sharedValue.isGridDataLoading = false;
    else
      this.refreshData();
  }

  public removeRow(data) {
    const rowNode = this.gridApi.getRowNode(data[this.gridConfigItems.rowKey].toString());
    this.gridData.splice(rowNode.rowIndex, 0, rowNode);
    this.gridApi.refreshInfiniteCache();
  }

  public uncheckItems(checkedItemIds) {
    if (this.gridData && this.gridData.length > 0) {
      const checkedRows = this.gridData.filter(g => checkedItemIds.indexOf(g[this.gridConfigItems.rowKey]) > -1);
      for (let i = 0; i < checkedRows.length; i++) {
        checkedRows[i].selected = false;
      }
      this.checkUncheckAll();
    }
  }

  public checkItems(checkedItemIds) {
    if (this.gridData && this.gridData.length > 0) {
      const checkedRows = this.gridData.filter(g => checkedItemIds.indexOf(g[this.gridConfigItems.rowKey]) > -1);
      for (let i = 0; i < checkedRows.length; i++) {
        checkedRows[i].selected = true;
      }
      this.checkUncheckAll();
    }
  }

  checkUncheckAll() {
    if (this.gridApi && this.gridConfigItems.enableRowSelection) {
      if (this.gridData && this.gridData.length > 0) {
        const checkedcount = this.gridData.filter(r => r.selected).length;
        if (checkedcount == this.gridData.length) {
          this.isAllChecked = true;
        }
        else {
          this.isAllChecked = false;
        }
        // this.gridApi.refreshHeader();
        this.refreshCheckbox();
      }
    }
  }

  public refreshCheckbox() {
    if (this.gridApi) {
      if (this.gridConfigItems.enableRowSelection) {
        const cbxColumn = this.columnDefs.find(c => c.field == 'selected');
        if (cbxColumn) {
          cbxColumn.headerComponentParams.isCheckAllAllowed = this.gridConfigItems.isCheckAllAllowed;
          cbxColumn.headerComponentParams.isAllChecked = this.isAllChecked;
        }
        this.updateGridColumnDefinitions(null);
      }
    }
  }

  public uncheckAll() {
    if (this.gridData) {
      for (let i = 0; i < this.gridData.length; i++) {
        this.gridData[i].selected = false;
      }
      this.checkUncheckAll();
    }
  }

  setDataInGrid(data) {
    if (data) {
      const rowNode = this.gridApi.getRowNode(data[this.gridConfigItems.rowKey].toString());
      const newData = data;
      rowNode.setData(newData);
      if (data.IsRowOnTop) {
        this.allGridData.splice(rowNode.rowIndex, 1);
        this.allGridData.splice(0, 0, rowNode.data);
      }
      else {
        this.allGridData.splice(rowNode.rowIndex, 1, rowNode.data);
      }
      this.refreshData();
    }
  }

  reorderUpdatedRecords(dataArray: any[]) {
    dataArray.forEach(data => {
      const rowNode = this.gridApi.getRowNode(data[this.gridConfigItems.rowKey].toString());
      const newData = data;
      rowNode.setData(newData);
      this.allGridData.splice(rowNode.rowIndex, 1);
      this.allGridData.splice(0, 0, rowNode.data);
    });
    this.refreshData();
  }

  onGridSearchTextChange(searchText) {
    this.searchText = searchText.detail.value;
    if(this.parentComponentName != '')
       this.searchTextChanged.emit(this.searchText); 
    else
       this.refreshData();
  }

  oncolumnResized(params) {
    this.autosizeHeaders(params);
  }

  onCellClicked(params) {
    if (params.column.colId === 'selected') {
      if (params.data.isCheckboxDisabled) {
        return;
      }
      params.data.selected = params.event.target.checked;
      this.checkUncheckAll();
    }
    this.onCellClick.emit(params);
  }

  onAllChecked(isChecked) {
    for (let i = 0; i < this.allGridData.length; i++) {
      if (!this.searchText && !this.filters) {
        if (!this.allGridData[i].isEditDisabled && !this.allGridData[i].isCheckboxDisabled) {
          this.allGridData[i].selected = isChecked;
        }
      }
      if (this.gridData.length > i) {
        if (!this.gridData[i].isEditDisabled && !this.gridData[i].isCheckboxDisabled) {
          this.gridData[i].selected = isChecked;
        }
      }
    }
    this.isAllChecked = isChecked;
    this.checkUncheckAll();
    this.onCheckAll.emit(isChecked);
  }

  refreshData() {
    this.sharedValue.isGridDataLoading = false;
    if (this.gridApi) {
      this.isDataLoading = false;
      this.sharedValue.isGridDataLoading = false;
      if (this.gridlastRowIndex > 0) {        
        this.gridApi.refreshInfiniteCache();
      }
      else {
        this.resetDataSource();
      }
    }
  }

  resetDataSource() {
    this.gridApi.setDatasource(this.getDataSource());
  }

  onDataLoadStart() {
    this.gridApi.showLoadingOverlay();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.resetSortModel();

    this.gridApi.setDatasource(this.getDataSource());
  }

  private debounceScroll() {
    
    clearTimeout(this.debounceTimeout);
    if(this.allGridData.length > 0)
    {    
      this.debounceTimeout = setTimeout(() => this.onScrollEnd(), 600);
    }
  }

  private onScrollEnd() {
    if (!this.gridApi || !this.hasMoreItems)
          return;
    const verticalPixelRange = this.gridApi.getVerticalPixelRange();
    const renderedRows = this.gridApi.getRenderedNodes().length;
    const rowHeight = this.gridOptions.rowHeight || 25; // Default row height is 25 if not set

    const totalGridHeight = renderedRows * rowHeight;
    const scrollPosition = verticalPixelRange.bottom;

    if (scrollPosition >= totalGridHeight) {
      console.log(this.currentOffset);
      this.currentOffset = this.currentOffset + 10; // Increment offset by 8
      this.onLoadMore(this.currentOffset,this.searchText);
    }
  }

  private getDataSource(): IDatasource {
    return {
      getRows: (params: IGetRowsParams) => {
        this.getRowData(params).subscribe(data => {
          this.gridlastRowIndex = 0;
          if (this.searchText) {
            this.gridlastRowIndex = this.gridData.length;
          }
          else {
            this.gridlastRowIndex = this.allGridData.length;
          }
          if (this.gridlastRowIndex > 0) {
            this.isDataLoading = false;
            this.gridApi.hideOverlay();
          }
          else if (this.isDataLoading) {
            this.gridApi.showLoadingOverlay();
          }
          else {
            this.gridApi.showNoRowsOverlay();
          }
          params.successCallback(data, this.gridlastRowIndex);
        });
      }
    };
  }

  private getRowData(params: IGetRowsParams) {
    this.sortAllRows(params);
    this.gridData = [];
    if (this.searchText) {
      this.gridData = this.filterRows();
    }
    else {
      this.gridData = this.allGridData;
    }
    const rowsForBlock = this.gridData.slice(params.startRow, params.endRow);
    return of(rowsForBlock);
  }

  private filterRows() {
    const rows = [];
    for (let i = 0; i < this.allGridData.length; i++) {
      for (let j = 0; j < this.columnDefs.length; j++) {
        const key = this.columnDefs[j].field;
        if (this.allGridData[i][key]) {
          let isRowVisible = false;
          if (this.searchText) {
            if (this.allGridData[i][key].toString().toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) {
              isRowVisible = true;
            }
          }

          if (isRowVisible) {
            rows.push(this.allGridData[i]);
            break;
          }
        }
      }
    }
    return rows;


  }

  private sortAllRows(params) {
    if (params.sortModel && params.sortModel.length > 0) {
      if (this.gridSortColumn != params.sortModel[0].colId
        || this.gridSortOrder != params.sortModel[0].sort
        || this.isRefresh) {
        params.startRow = 0;
        params.endRow = this.gridOptions.cacheBlockSize;
        this.gridSortColumn = params.sortModel[0].colId;
        this.gridSortOrder = params.sortModel[0].sort;
        this.allGridData = this.sortByKey(this.allGridData, this.gridSortColumn, this.gridSortOrder);
      }
    }
    else {
      if (!this.gridSortColumn || !this.gridSortOrder || this.isRefresh) {
        params.startRow = 0;
        params.endRow = this.gridOptions.cacheBlockSize;
        this.gridSortColumn = this.gridConfigItems.defaultSortColumn;
        this.gridSortOrder = this.gridConfigItems.defaultSortOrder;
        this.allGridData = this.sortByKey(this.allGridData, this.gridSortColumn, this.gridSortOrder);
      }
    }
    this.isRefresh = false;
  }

  private sortByKey(array, key, order) {
    let result;

    if (order == 'asc') {
      result = array.sort((a, b) => {
        let x = a[key]; let y = b[key];
        if (typeof x === 'string' || x instanceof String) {
          x = x ? x.toString().toLowerCase() : null;
          y = y ? y.toString().toLowerCase() : null;
        }
        return !x ? -1 : ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }
    else {
      result = array.sort((a, b) => {
        let x = a[key]; let y = b[key];
        if (typeof x === 'string' || x instanceof String) {
          x = x ? x.toString().toLowerCase() : null;
          y = y ? y.toString().toLowerCase() : null;
        }
        return !y ? -1 : ((x > y) ? -1 : ((x < y) ? 1 : 0));
      });
    }
    return result;
  }

  private autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(this.header_MIN_HEIGHT);
      const headerCells = document.querySelectorAll('#myGrid .ag-header-cell-label');
      let minHeight = this.header_MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
      event.api.setGroupHeaderHeight(this.group_MIN_HEIGHT);
    }
  }

  public refreshGridCells(columnList: string[]) {
    if (columnList) {
      this.gridApi.refreshCells({
        columns: columnList,
        force: true,
        suppressFlash: true
      });
    }
  }

  public redrawGridRows() {
    this.gridApi.redrawRows();
  }

 public onButtonClick() {
    this.onBtnClick.emit();
  }

  exportData() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.gridConfigItems.sheetName);
    worksheet.columns = [];
    const sheetColumns = [];
    this.columnDefs.forEach(element => {
      if(element.field !='userActionField')
        {sheetColumns.push({ header: element.headerName, key: element.field, width: 30 });}
    });
    worksheet.columns = sheetColumns;
    worksheet.addRows(this.allGridData, 'n');
    const data = this.allGridData;
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.gridConfigItems.workbookName);
    });
  }

}


